export default {
  getSaitiInfo: (state, data) => {
    state.sitiInfo = data
  },

  getNewsInfo: (state, data) => {
    state.newsInfo = data
  },

  setSiteLang(state, lang) {
    state.currentLanguge = lang
  },

  setSiteMsgnum(state, msgnum) {
    state.msgnum = msgnum
  }
}
