<template>
  <div class="stform personapply" v-if="personapply">
    <div class="pagePopMask pFull" @click.self="closePop"></div>
    <div class="pagePop w1280 pCenter">
      <div class="w">
        <div class="tit fnt_32">{{ globalDataArray.siteTemplateLanguage.person_apply_record }}</div>

        <div class="cont">
          <div class="compCommonInfo fnt_18">
            <span class="w50">{{ globalDataArray.siteTemplateLanguage.team_name }}：{{ datainfo.teamName }}</span>
            <span class="w50">{{ globalDataArray.siteTemplateLanguage.team_type }}：{{ datainfo.teamType_dictText }}</span>
          </div>

          <div class="pad_0_16">
            <!-- 国内成人 -->
            <template v-if="templateId == '01' && nation == '01'">
              <div class="examinePopList fnt_18">
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_name }}：</span>{{ datainfo.userName }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_phone }}：</span>{{ datainfo.userPhone }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_email }}：</span>{{ datainfo.userMail }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_company_type }}：</span>{{ datainfo.companyType_dictText }}</div>
                <div class="exaitem">
                  <span class="c-gray" v-if="datainfo.companyType=='02'">{{ globalDataArray.siteTemplateLanguage.team_member_school }}：</span>
                  <span class="c-gray" v-else>{{ globalDataArray.siteTemplateLanguage.team_user_company_name }}：</span>
                  {{ datainfo.company }}
                </div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_job_type }}：</span>{{ datainfo.jobType_dictText }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_city }}：</span>{{ datainfo.countryName }}{{ datainfo.cityName }}</div>
              </div>
            </template>

            <!-- 国外成人 -->
            <template v-if="templateId == '01' && nation == '02'">
              <div class="examinePopList fnt_18">
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_name }}：</span>{{ datainfo.userName }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_email }}：</span>{{ datainfo.userMail }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.person_form_country }}：</span>{{ datainfo.countryName }}{{ datainfo.cityName }}</div>
              </div>
            </template>

            <!-- 国内青少年 -->
            <template v-if="templateId == '02' && nation == '01'">
              <div class="examinePopList fnt_18">
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_name }}：</span>{{ datainfo.userName }}</div>
                <div class="exaitem">
                  <span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_school }}：</span>
                  {{ datainfo.company }}
                </div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_city }}：</span>{{ datainfo.countryName }}{{ datainfo.cityName }}</div>
              </div>
            </template>

            <!-- 国外青少年 -->
            <template v-if="templateId == '02' && nation == '02'">
              <div class="examinePopList fnt_18">
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_name }}：</span>{{ datainfo.userName }}</div>
                <div class="exaitem">
                  <span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_school }}：</span>
                  {{ datainfo.company }}
                </div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.person_form_country }}：</span>{{ datainfo.countryName }}{{ datainfo.cityName }}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="close" @click.self="closePop"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Personapply',
  props: {
    personapply: {
      type: Boolean,
      default: false
    },
    curid: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      datainfo: {
        teamName: '',
        teamType: '',
        userName: '',
        userPhone: '',
        userMail: '',
        companyType: '',
        company: '',
        jobType: '',
        countryName: '',
        countryCode: '',
        cityName: '',
        cityCode: '',
        templateId: '',
        nation: ''
      }
    }
  },
  watch: {
    curid: {
      immediate: true,
      handler(record) {
        if (record != '') {
          this.applyRecord(record)
          // 获取信息
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    applyRecord(record) {
      this.datainfo = record
      this.templateId = record.templateId
      this.nation = record.nation
      console.log(this.datainfo, 'dateinfo')
    },
    closePop() {
      this.$emit('update:personapply', false)
    }
  }
}
</script>
