// import Vue from 'vue'
import axios from 'axios'
// import store from '@/store'
import { VueAxios } from './axios'
// import { getUrlKey, getCookie,getCookieuUne } from '@/utils/util'
import Vue from 'vue'
import { Modal, message } from 'ant-design-vue'
// 解析post方式的字符串
import qs from 'qs'
// 引入路由router
import createRouter from '@/router'
import Cookies from 'js-cookie'

const router = createRouter()

// import { ACCESS_TOKEN } from '@/store/mutation-types'
// let inApp = false
// 创建 axios 实例
// 是否展示错误弹窗
const noToastFlag = []

const service = axios.create({
  baseURL: process.env.VUE_ENV === 'server' ? process.env.VUE_APP_BASE_URL : '/api',
  timeout: 30000 // 请求超时时间
})

let currentLanguge = 'zh_CN' // en_US

if (Cookies.get('DEFAULT_LANG') != null && Cookies.get('DEFAULT_LANG') != undefined) {
  currentLanguge = Cookies.get('DEFAULT_LANG')
}

// JS提示
let httpTipsTxt = {
  refuse: '拒绝访问',
  loginagain: '很抱歉，登录已过期，请重新登录~',
  confirm: '确认',
  cancel: '取消',
  nofound: '抱歉，资源未找到!',
  timeout: '网络超时',
  pleaseLogin: '请先登录'
}

if (currentLanguge == 'en_US') {
  httpTipsTxt = {
    refuse: 'Access denied',
    loginagain: 'Sorry, your login has expired. Please log in again',
    confirm: 'Confirm',
    cancel: 'Cancel',
    nofound: 'Sorry, the resource was not found',
    timeout: 'Network timeout.',
    pleaseLogin: 'Please log in first'
  }
}

// 俄语
if (currentLanguge == 'rus') {
  httpTipsTxt = {
    refuse: 'Отказ в доступе',
    loginagain: 'Извините, срок действия входа истек , повторите вход',
    confirm: 'Подтверждение',
    cancel: 'Отменить',
    nofound: 'Извините, ресурсы не найдены',
    timeout: 'Тайм-аут сети',
    pleaseLogin: 'Пожалуйста, войдите сначала'
  }
}

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    let token = ''
    if (Cookies.get('saiti_access_info') != '' && Cookies.get('saiti_access_info') != undefined && Cookies.get('saiti_access_info') != null) {
      token = JSON.parse(Cookies.get('saiti_access_info')).token
    }
    // window.localStorage.getItem("token");
    // const token = Vue.ls.get(ACCESS_TOKEN)
    // console.log("------异常响应------",token)
    console.log('------异常响应------', error.response.status)
    switch (error.response.status) {
      case 403:
        if (typeof document === 'object') {
          message.error(httpTipsTxt.refuse)
        } else {
          console.log(httpTipsTxt.refuse)
        }

        break
      case 500:
        console.log('------error.response------', error.response)
        // update-begin- --- author:liusq ------ date:20200910 ---- for:处理Blob情况----
        const type = error.response.request.responseType
        if (type === 'blob') {
          blobToJson(data)
          break
        }
        // update-end- --- author:liusq ------ date:20200910 ---- for:处理Blob情况----
        if (token && data.message.includes('Token失效')) {
          // Modal.confirm({
          //     title: httpTipsTxt.loginagain,
          //     content: '',
          //     okText: httpTipsTxt.confirm,
          //     cancelText: httpTipsTxt.cancel,
          //     onOk() {
          //       //console.log('OK');
          //     },
          //     onCancel() {
          //       console.log('Cancel');
          //     }
          // })
          // .then(() => {
          //   window.localStorage.removeItem('token');
          //   window.localStorage.removeItem('username');
          //   window.location.reload()
          // })
          if (Cookies.get('saiti_access_info') != undefined) {
            Cookies.remove('saiti_access_info', { domain: process.env.NODE_ENV === 'production' ? '.atomgit.com' : undefined, path: '/' })
          }

          if (typeof document === 'object') {
            message.error(httpTipsTxt.pleaseLogin)
          } else {
            console.log(httpTipsTxt.pleaseLogin)
          }
          setTimeout(() => {
            router.push({ name: 'home' })
            location.reload()
          }, 1000)
          // update-end- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
        }
        break
      case 404:
        if (typeof document === 'object') {
          message.warning(httpTipsTxt.nofound)
        } else {
          console.log(httpTipsTxt.nofound)
        }
        break
      case 504:
        if (typeof document === 'object') {
          message.warning(httpTipsTxt.timeout)
        } else {
          console.log(httpTipsTxt.timeout)
        }
        break
      case 401:

        if (Cookies.get('saiti_access_info') != undefined) {
          Cookies.remove('saiti_access_info', { domain: process.env.NODE_ENV === 'production' ? '.atomgit.com' : undefined, path: '/' })
        }
        if (typeof document === 'object') {
          message.error(httpTipsTxt.pleaseLogin)
        } else {
          console.log(httpTipsTxt.pleaseLogin)
        }
        setTimeout(() => {
          router.push({ name: 'home' })
          location.reload()
        }, 1000)
        break
      default:
        if (typeof document === 'object') {
          message.warning(httpTipsTxt.timeout)
        } else {
          console.log(httpTipsTxt.timeout)
        }
        break
    }
  } else if (error.message) {
    if (error.message.includes('timeout')) {
      if (typeof document === 'object') {
        message.warning(httpTipsTxt.timeout)
      } else {
        console.log(httpTipsTxt.timeout)
      }
    } else {
      if (typeof document === 'object') {
        message.error(error.message)
      } else {
        console.log(error.message)
      }
    }
  }

  return Promise.reject(error)
}

// 生成通用必传参数
// const createParameters = function () {
//   inApp = (getUrlKey('appType') === 'android' || getUrlKey('appType') === 'ios' || getCookie('publicParams'))
//   let params = {
//     k:store.state.shortToken,  // 访问的会话token
//   }
//   if(inApp){
//     params = JSON.parse(getCookie('publicParams'))
//     for(let key in params){
//       params[key] = decodeURI(params[key])
//     }
//   }
//   return params
// }
// 封装拼接通用必传参数
const addParameters = function(config) {
  // const parameters = createParameters()
  const parameters = ''
  // 确认订单 需要json
  if (config.headers['Content-Type'] === 'application/json') {
    if (config.url === 'competition/signup/submit') {
      config.data = config.data
    } else {
      config.data = {
        ...parameters,
        ...config.data
      }
    }
  } else if (config.method === 'get') {
    config.params = {
      ...parameters,
      ...config.params
    }
  } else if (config.method === 'post' && config.headers['Content-Type'] !== 'multipart/form-data') {
    const data = qs.parse(config.data)
    config.data = qs.stringify({
      ...parameters,
      ...data
    })
  }
  return config
}

// 请求拦截器
service.interceptors.request.use(config => {
  // 设置7天登录
  // (function(){
  //   var sessionToken = window.sessionStorage.getItem("token");
  //   var cookToken = getCookieuUne("token");
  //   if(sessionToken){
  //     store.commit('memoryData',{'shortToken':sessionToken})
  //     return
  //   }else if(cookToken&&cookToken!='null'){
  //     store.commit('memoryData',{'shortToken':cookToken})
  //     return
  //   }else{
  //     store.commit('memoryData',{'shortToken':'0'})
  //   }
  // })()
  // 在此添加参数，这里只会当接口被调用时才会真正触发
  config = addParameters(config)
  return config
}, err)

// 响应拦截器
service.interceptors.response.use((resConfig) => {
  // 拦截长token, 用户未登录或者登录失效
  // console.log(resConfig)
  if (!resConfig.data.success && resConfig.data.code == 502) {
    router.push({
      name: 'login',
      query: { redirect: router.currentRoute.fullPath }
    })

    if (resConfig.data.msg && resConfig.data.code != '1060000') {
      Toast(resConfig.data.msg)
    }

    return resConfig.data
  }
  // 拦截短token过期--本地短token可能存在,但是后端返回过期。重新获取短token,判断长token是否存在，如果存在并且过期，跳转登录页。如果不存在，说明不必登录,只需短token重新设置即可。
  else if (resConfig.data.code === 1060001) {
    // 没有token的话请求接口获取，注意这里的路径，由于是devserver的proxy代理
    console.log(resConfig, '注释store')
    // return axios.post(process.env.VUE_APP_BASE_URL + '/login', qs.stringify(createParameters())).then(function (res) {
    //   // 请求成功，存储token
    //   if (res.success) {
    //     // 短token过期的话，在请求新token之后，要重新请求接口
    //     window.sessionStorage.setItem("token",res.result.token);
    //     store.commit('setShortToken', res.result.token)
    //     // 如果本地存储长token存在，进行静默登录，过期的话--->跳转登录页。
    //     // return new Promise(function (resolve) {
    //     //   resolve(validateLongToken(resConfig))
    //     // })
    //   } else {
    //     Toast('网络错误,请稍后再试~')
    //     return Promise.resolve('网络错误,请稍后再试~')
    //   }
    // })
  }
  else if (resConfig.data.code === 200) {
    return resConfig.data
  } else {
    if (resConfig.data.msg && !noToastFlag.includes(resConfig.config.url)) {
      // Toast(`${resConfig.config.url.split('/').pop()}|${resConfig.data.msg}`)
      Toast(`${resConfig.data.msg}`)
    }
    return Promise.resolve(resConfig.data)
  }
}, err)

// const noGetAdd = true
function api({ method, data, contentType = 'urlencoded', noToast = false, hastoken = false }, url) {
  if (noToast) {
    noToastFlag.push(url)
  }

  // console.log("store.state.shortToken",store.state.shortToken)
  // 1.判断本地是否有token
  // if (store.state.shortToken === '0' && noGetAdd) {
  //   noGetAdd = false
  //   return new Promise( (resolve, reject) => {
  //   })
  // } else {
  // 2.有token的话
  if (method && method.toLowerCase() === 'get') {
    var contentTypeObj = {
      urlencoded: 'application/x-www-form-urlencoded', // /application/x-www-form-urlencoded   接口500修改gy
      json: 'application/json',
      formData: 'multipart/form-data'
    }

    // console.log(data,url)
    if (url == '/init' && data.lang) { // 初始化语言包接口处理参数
      currentLanguge = data.lang
    }

    const config = {
      headers: {
        'Content-Type': contentTypeObj[contentType],
        'language': currentLanguge // 语言版本标识，默认zh
      }
    }

    if (hastoken) {
      let token = ''
      if (Cookies.get('saiti_access_info') != '' && Cookies.get('saiti_access_info') != undefined && Cookies.get('saiti_access_info') != null) {
        token = JSON.parse(Cookies.get('saiti_access_info')).token
      }
      config.headers['X-Access-Token'] = token
    }

    if (contentType === 'json') {
      data = qs.parse(data)
    }

    return service.get(url, config)
    // return service.get(url, {params: data},config)
    // return service.get(url, data,config)
  } else if (method && method.toLowerCase() === 'post') {
    const contentTypeObj = {
      urlencoded: 'application/x-www-form-urlencoded', // /application/x-www-form-urlencoded   接口500修改gy
      json: 'application/json',
      jsonArray: 'application/json',
      formData: 'multipart/form-data'
    }

    const config = {
      headers: {
        'Content-Type': contentTypeObj[contentType],
        'language': currentLanguge // 语言版本标识，默认zh
      }
    }

    if (hastoken) {
      let token = ''
      if (Cookies.get('saiti_access_info') != '' && Cookies.get('saiti_access_info') != undefined && Cookies.get('saiti_access_info') != null) {
        token = JSON.parse(Cookies.get('saiti_access_info')).token
      }
      config.headers['X-Access-Token'] = token
    }

    // console.log(contentType, 'contentType')
    if (contentType === 'json') {
      data = qs.parse(data)
    }
    // console.log(config)
    return service.post(url, data, config)
  } else if (method && method.toLowerCase() === 'put') {
    const contentTypeObj = {
      urlencoded: 'application/json', // /application/x-www-form-urlencoded   接口500修改gy
      json: 'application/json',
      formData: 'multipart/form-data'
    }

    const config = {
      headers: {
        'Content-Type': contentTypeObj[contentType],
        'language': currentLanguge // 语言版本标识，默认zh
      }
    }

    if (hastoken) {
      let token = ''
      if (Cookies.get('saiti_access_info') != '' && Cookies.get('saiti_access_info') != undefined && Cookies.get('saiti_access_info') != null) {
        token = JSON.parse(Cookies.get('saiti_access_info')).token
      }
      config.headers['X-Access-Token'] = token
    }

    if (contentType === 'json') {
      data = qs.parse(data)
    }

    return service.put(url, data, config)
  } else if (method && method.toLowerCase() === 'delete') {
    const contentTypeObj = {
      urlencoded: 'application/json', // /application/x-www-form-urlencoded   接口500修改gy
      json: 'application/json',
      formData: 'multipart/form-data'
    }

    const config = {
      headers: {
        'Content-Type': contentTypeObj[contentType],
        'language': currentLanguge // 语言版本标识，默认zh
      }
    }

    if (hastoken) {
      let token = ''
      if (Cookies.get('saiti_access_info') != '' && Cookies.get('saiti_access_info') != undefined && Cookies.get('saiti_access_info') != null) {
        token = JSON.parse(Cookies.get('saiti_access_info')).token
      }
      config.headers['X-Access-Token'] = token
    }

    if (contentType === 'json') {
      data = qs.parse(data)
    }

    return service.delete(url, config)
  }
  // }
}

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

/**
 * Blob解析
 * @param data
 */
function blobToJson(data) {
  const fileReader = new FileReader()
  const token = Vue.ls.get(ACCESS_TOKEN)
  fileReader.onload = function() {
    try {
      const jsonData = JSON.parse(this.result) // 说明是普通对象数据，后台转换失败
      // console.log("jsonData",jsonData)
      if (jsonData.status === 500) {
        console.log('token----------》', token)
        if (token && jsonData.message.includes('Token失效')) {
          Modal.confirm({
            title: httpTipsTxt.loginagain,
            content: '',
            okText: httpTipsTxt.confirm,
            cancelText: httpTipsTxt.cancel,
            onOk() {
              console.log('OK')
            },
            onCancel() {
              console.log('Cancel')
            }
          })
        }
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log('blob解析fileReader返回err', err)
    }
  }
  fileReader.readAsText(data)
}

export {
  installer as VueAxios,
  api as axios
}

// 定义返回promise对象的接口
const $http = service
$http.defaults.headers.post['Content-Type'] = 'application/json'

export function httpPost(url, data = {}) {
  return new Promise((resolve, reject) => {
    $http.post(url, data).then(response => {
      resolve(response.result)
    }, err => {
      reject(err)
    }).catch(err => {
      reject(err)
    })
  })
}

export function httpGet(url, data = {}, lang) {
  return new Promise((resolve, reject) => {
    if (data.url) {
      url = url + data.url
      data = {}
    }
    $http.get(url, {
      params: data,
      headers: {
        language: lang
      }
    }).then(response => {
      // console.log(lang,'55555')
      resolve(response.result)
    }, err => {
      reject(err)
    }).catch(err => {
      reject(err)
    })
  })
}
