<template>
  <div class="signway" v-if="signway">
    <!-- 选择报名方式弹窗 -->
    <div class="pagePopMask pFull" @click.self="closePop"></div>
    <div class="pagePop pCenter">
      <div class="w">
        <div class="tit fnt_32">{{ globalDataArray.siteTemplateLanguage.sign_up }}</div>

        <div class="cont txtCont">
          <div class="editorFont fnt_20">{{ globalDataArray.siteTemplateLanguage.select_join_team }}</div>
        </div>

        <div class="btnBox btnBox3 fnt_18">
          <div class="btn btn1" @click="btnClick('1')">{{ globalDataArray.siteTemplateLanguage.to_sign_up }}</div>
          <div class="btn btn2" @click="btnClick('2')">{{ globalDataArray.siteTemplateLanguage.to_join_team }}</div>
        </div>

        <div class="close" @click.self="closePop"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Signway',
  props: {
    signway: {
      type: Boolean,
      default: false
    },
    curInfoArray: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {

    btnClick(res) {
      // 自定义事件，把res传给父组件,发射事件
      this.$emit('itemclick', res) // itemclick  为事件名
      this.$emit('update:signway', false)
    },

    closePop() {
      this.$emit('update:signway', false)
    }
  }
}
</script>
