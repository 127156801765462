<template>
  <div class="personedit" v-if="personedit">
    <div class="pagePopMask pFull" v-if="!showEditpop" @click.self="closePop"></div>
    <div class="pagePop w1280 pCenter" v-if="!showEditpop">
      <div class="w">
        <div class="tit fnt_32">{{ globalDataArray.siteTemplateLanguage.person_team_member }}</div>

        <div class="cont">
          <div class="compCommonInfo">
            <span class="">{{ globalDataArray.siteTemplateLanguage.team_name }}：{{ teamName }}</span>
            <span class="">{{ globalDataArray.siteTemplateLanguage.team_type }}：{{ teamType }}</span>
            <span class="">{{ globalDataArray.siteTemplateLanguage.create_time }}：{{ teamCreateTime }}</span>
          </div>

          <!-- 国内成人 -->
          <div class="antDesignTable gncr" v-if="templateId == '01'">
            <a-table
              row-key="id"
              :show-header="false"
              :pagination="false"
              :scroll="{x:true}"
              :columns="columns"
              :data-source="list"
              class="j-table-force-nowrap"
            >
              <template slot="one" slot-scope="text, record">
                <div class="captainLabel relative" :class="curLang != '' && curLang != 'zh_CN' ? 'rus' : ''" v-if="record.nation=='01'">
                  <div><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_name }}：</span>{{ record.userName }}</div>
                  <div><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_company_type }}：</span>{{ record.companyType_dictText }}</div>
                  <div class="label" v-if="record.isCaptain==1">{{ globalDataArray.siteTemplateLanguage.team_leader }}</div>
                </div>

                <div class="captainLabel relative" :class="curLang != '' && curLang != 'zh_CN' ? 'rus' : ''" v-if="record.nation=='02'">
                  <div><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_name }}：</span>{{ record.userName }}</div>
                  <div class="label" v-if="record.isCaptain==1">{{ globalDataArray.siteTemplateLanguage.team_leader }}</div>
                </div>
              </template>
              <template slot="two" slot-scope="text, record">
                <div v-if="record.nation=='01'"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_phone }}：</span>{{ record.userPhone }}</div>
                <div v-if="record.nation=='01'">
                  <span class="c-gray" v-if="record.companyType=='02'">{{ globalDataArray.siteTemplateLanguage.team_member_school }}：</span>
                  <span class="c-gray" v-else>{{ globalDataArray.siteTemplateLanguage.team_user_company_name }}：</span>
                  {{ record.company }}
                </div>
              </template>
              <template slot="three" slot-scope="text, record">
                <div v-if="record.nation=='01'"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_email }}：</span>{{ record.userMail }}</div>
                <div v-if="record.jobType_dictText!=''&&record.jobType_dictText!=null"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_job_type }}：</span>{{ record.jobType_dictText }}</div>

                <div v-if="record.nation=='02'"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_email }}：</span>{{ record.userMail }}</div>
              </template>
              <template slot="four" slot-scope="text, record">
                <div>
                  <span class="c-gray">{{ globalDataArray.siteTemplateLanguage.person_area }}：</span>{{ record.countryName }}{{ record.cityName }}
                </div>
              </template>
              <template slot="action" slot-scope="text, record" v-if="record.isCaptain==0 && isEditOrDelete == 1 && curid.competitionDelflag=='0' && curid.competitionStatus=='1' && curid.competitionSignup=='03' && currentTime < curid.localEndtimeStr">
                <div class="compEdit" @click="editFun(record.id)">{{ globalDataArray.siteTemplateLanguage.edit }}</div>
                <div class="compDel" @click="delFun(record.id,record.competitionParentId,record.memberId,record.userMail,record.teamName)">
                  {{ globalDataArray.siteTemplateLanguage.delete }}
                </div>
              </template>
            </a-table>
            <a-pagination
              :v-model="current" :total="total"
              :page-size="pageSize"
              @change="turnPageFun" show-less-items
            />
            <!--               <a-pagination v-model:current="current" :total="500" /> -->
          </div>

          <!-- 国外成人 -->
          <!-- <div class="antDesignTable gycr" v-if="templateId == '01' && nation == '02'">
              <a-table
                rowKey="id"
                :showHeader="false"
                :pagination="false"
                :scroll="{x:true}"
                :columns="columns"
                :dataSource="list"
                class="j-table-force-nowrap">
                <template slot="one" slot-scope="text, record">
                  <div class="captainLabel relative" :class="curLang != '' && curLang != 'zh_CN' ? 'rus' : ''">
                    <div><span class="c-gray">{{globalDataArray.siteTemplateLanguage.team_user_name}}</span>{{record.userName}}</div>
                    <div class="label" v-if="record.isCaptain==1">{{globalDataArray.siteTemplateLanguage.team_leader}}</div>
                  </div>
                </template>
                <template slot="two" slot-scope="text, record">

                </template>
                <template slot="three" slot-scope="text, record">
                  <div><span class="c-gray">{{globalDataArray.siteTemplateLanguage.team_user_email}}</span>{{record.userMail}}</div>
                </template>
                <template slot="four" slot-scope="text, record">
                  <div>
                    <span class="c-gray">{{globalDataArray.siteTemplateLanguage.person_area}}</span>{{record.countryName}}{{record.cityName}}
                  </div>
                </template>
                <template slot="action" slot-scope="text, record" v-if="record.isCaptain==0 && isEditOrDelete == 1 && curid.competitionDelflag=='0' && curid.competitionStatus=='1' && curid.competitionSignup=='03' && currentTime < curid.localEndtimeStr">
                    <div class="compEdit" @click="editFun(record.id)">{{globalDataArray.siteTemplateLanguage.edit}}</div>
                    <div class="compDel" @click="delFun(record.id,record.competitionParentId,record.memberId,record.userMail,record.teamName)">
                    {{globalDataArray.siteTemplateLanguage.delete}}</div>
                  </template>
              </a-table>
              <a-pagination :v-model="current" :total="total"
                :pageSize="pageSize"
                @change="turnPageFun" show-less-items />
            </div> -->

          <!-- 国内青少年 -->
          <div class="antDesignTable qsn" v-if="templateId == '02' && nation == '01'">
            <a-table
              row-key="id"
              :show-header="false"
              :pagination="false"
              :scroll="{x:true}"
              :columns="columns"
              :data-source="list"
              class="j-table-force-nowrap"
            >
              <template slot="one" slot-scope="text, record">
                <div class="captainLabel relative" :class="curLang != '' && curLang != 'zh_CN' ? 'rus' : ''">
                  <div v-if="record.isleader"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.leader_name }}：</span>{{ record.leaderName }}</div>
                  <div v-else><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_name }}：</span>{{ record.userName }}</div>
                  <div class="label" v-if="record.isleader">{{ globalDataArray.siteTemplateLanguage.leader_label }}</div>
                  <div class="label" v-if="record.isCaptain=='1'">{{ globalDataArray.siteTemplateLanguage.team_leader }}</div>
                </div>
              </template>
              <template slot="two" slot-scope="text, record">
              </template>
              <template slot="three" slot-scope="text, record">
                <div v-if="record.userPhone&&record.isleader"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_phone }}：</span>{{ record.userPhone }}</div>
                <div v-if="!record.isleader"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_school }}：</span>{{ record.company }}</div>
              </template>
              <template slot="four" slot-scope="text, record">
                <div v-if="!record.isleader">
                  <span class="c-gray">{{ globalDataArray.siteTemplateLanguage.person_area }}：</span>{{ record.countryName }}{{ record.cityName }}
                </div>
                <div v-if="record.userMail&&record.isleader"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_email }}：</span>{{ record.userMail }}</div>
              </template>
              <template slot="action" slot-scope="text, record" v-if="!record.isleader && isEditOrDelete == 1 && curid.competitionDelflag=='0' && curid.competitionStatus=='1' && curid.competitionSignup=='03' && currentTime < curid.localEndtimeStr">
                <div class="compEdit" @click="editFun(record.id)">{{ globalDataArray.siteTemplateLanguage.edit }}</div>
                <div class="compDel" v-if="record.isCaptain!='1'" @click="delFun(record.id,record.competitionParentId,record.memberId,record.userMail,record.teamName)">
                  {{ globalDataArray.siteTemplateLanguage.delete }}
                </div>
              </template>
            </a-table>
            <a-pagination
              :v-model="current" :total="total"
              :page-size="pageSize"
              @change="turnPageFun" show-less-items
            />
          </div>

          <!-- 国外青少年 -->
          <div class="antDesignTable gwqsn" v-if="templateId == '02' && nation == '02'">
            <a-table
              row-key="id"
              :show-header="false"
              :pagination="false"
              :scroll="{x:true}"
              :columns="columns"
              :data-source="list"
              class="j-table-force-nowrap"
            >
              <template slot="one" slot-scope="text, record">
                <div class="captainLabel relative" :class="curLang != '' && curLang != 'zh_CN' ? 'rus' : ''">
                  <div v-if="record.isleader"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.leader_name }}：</span>{{ record.leaderName }}</div>

                  <div v-else><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_name }}：</span>{{ record.userName }}</div>
                  <div class="label" v-if="record.isleader">{{ globalDataArray.siteTemplateLanguage.leader_label }}</div>
                  <div class="label" v-if="record.isCaptain=='1'">{{ globalDataArray.siteTemplateLanguage.team_leader }}</div>
                </div>
              </template>
              <template slot="two" slot-scope="text, record">
              </template>
              <template slot="three" slot-scope="text, record">
                <div v-if="record.userPhone&&record.isleader"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_phone }}：</span>{{ record.userPhone }}</div>
                <div v-if="!record.isleader"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_school }}：</span>{{ record.company }}</div>
              </template>
              <template slot="four" slot-scope="text, record">
                <div v-if="!record.isleader">
                  <span class="c-gray">{{ globalDataArray.siteTemplateLanguage.person_area }}：</span>{{ record.countryName }}{{ record.cityName }}
                </div>
                <div v-if="record.userMail&&record.isleader"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_email }}：</span>{{ record.userMail }}</div>
              </template>
              <template slot="action" slot-scope="text, record" v-if="!record.isleader && isEditOrDelete == 1 && curid.competitionDelflag=='0' && curid.competitionStatus=='1' && curid.competitionSignup=='03' && currentTime < curid.localEndtimeStr">
                <div class="compEdit" @click="editFun(record.id)">{{ globalDataArray.siteTemplateLanguage.edit }}</div>
                <div class="compDel" v-if="record.isCaptain!='1'" @click="delFun(record.id,record.competitionParentId,record.memberId,record.userMail,record.teamName)">
                  {{ globalDataArray.siteTemplateLanguage.delete }}
                </div>
              </template>
            </a-table>
            <a-pagination
              :v-model="current" :total="total"
              :page-size="pageSize"
              @change="turnPageFun" show-less-items
            />
          </div>
        </div>

        <div class="close" @click.self="closePop"></div>
      </div>
    </div>

    <!-- 编辑成员信息弹窗 -->
    <div class="pagePopMask pFull" v-if="showEditpop" @click="cancelEdit"></div>
    <div class="pagePop w1280 pCenter" v-if="showEditpop">
      <div class="w">
        <div class="tit fnt_32">{{ globalDataArray.siteTemplateLanguage.person_team_member }}</div>
        <div class="cont" v-if="editTemplate == '01' && editNation == '01'">
          <div class="stform">
            <a-form :form="form">
              <div class="item">
                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_name">
                  <a-input
                    v-decorator="['userInfoArray.userName', {initialValue:userInfoArray.userName,rules:[{required:true,message:globalDataArray.siteTemplateLanguage.person_form_please_user_name}]}]"
                  />
                </a-form-item>
                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_phone">
                  <a-input
                    v-decorator="['userInfoArray.userPhone',{initialValue:userInfoArray.userPhone,rules:[{required:true,message:globalDataArray.siteTemplateLanguage.enter_member_tel}]}]"
                  />
                </a-form-item>
              </div>

              <div class="item">
                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_email">
                  <a-input
                    v-decorator="['userInfoArray.userMail',{initialValue:userInfoArray.userMail,rules:[{required:true,message:globalDataArray.siteTemplateLanguage.team_apply_user_email}]}]"
                  />
                </a-form-item>
                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_company_type">
                  <a-radio-group @change="onChange" v-decorator="['userInfoArray.companyType', { initialValue:userInfoArray.companyType,rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_company_type}] }]">
                    <a-radio :style="radioStyle" :value="item.itemValue" v-for="(item,index) in companyType" :key="index">{{ item.itemText }}</a-radio>
                  </a-radio-group>
                </a-form-item>
              </div>

              <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_school">
                <a-input
                  v-decorator="['userInfoArray.company',{initialValue:userInfoArray.company, rules:[{required:true,message:globalDataArray.siteTemplateLanguage.person_please_form_company_name}]}]"
                />
              </a-form-item>
              <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_job_type">
                <a-radio-group @change="onChange" v-decorator="['userInfoArray.jobType', {initialValue:userInfoArray.jobType }]">
                  <a-radio :style="radioStyle" :value="item.itemValue" v-for="(item,index) in jobType" :key="index">{{ item.itemText }}</a-radio>
                </a-radio-group>
              </a-form-item>
              <div class="item">
                <a-form-item :label="globalDataArray.siteTemplateLanguage.person_form_province">
                  <a-select :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body;}" @change="handleProvinceChange" :label-in-value="true" v-decorator="['userInfoArray.countryCode', { initialValue:userInfoArray.countryCode, rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_province }] }]">
                    <a-select-option v-for="prov in provinceList" :key="prov.parentId">
                      {{ prov.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_city">
                  <a-select :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body;}" :label-in-value="true" v-decorator="['userInfoArray.cityCode', { initialValue:userInfoArray.cityCode }]">
                    <a-select-option v-for="city in cityList" :key="city.parentId">
                      {{ city.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
            </a-form>
          </div>
        </div>
        <div class="cont" v-if="editTemplate == '02' && editNation == '01'">
          <div class="stform">
            <a-form :form="form">
              <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_name">
                <a-input
                  v-decorator="['userInfoArray.userName', {initialValue:userInfoArray.userName,rules:[{required:true,message:globalDataArray.siteTemplateLanguage.person_form_please_user_name}]}]"
                />
              </a-form-item>
              <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_school">
                <a-input
                  v-decorator="['userInfoArray.company',{initialValue:userInfoArray.company, rules:[{required:true,message:globalDataArray.siteTemplateLanguage.person_please_form_school_name}]}]"
                />
              </a-form-item>
              <div class="item">
                <a-form-item :label="globalDataArray.siteTemplateLanguage.person_form_province">
                  <a-select :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body;}" @change="handleProvinceChange" :label-in-value="true" v-decorator="['userInfoArray.countryCode', { initialValue:userInfoArray.countryCode, rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_province }] }]">
                    <a-select-option v-for="prov in provinceList" :key="prov.parentId">
                      {{ prov.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_city">
                  <a-select :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body;}" :label-in-value="true" v-decorator="['userInfoArray.cityCode', { initialValue:userInfoArray.cityCode }]">
                    <a-select-option v-for="city in cityList" :key="city.parentId">
                      {{ city.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
            </a-form>
          </div>
        </div>

        <div class="cont" v-if="editTemplate == '01' && editNation == '02'">
          <div class="stform">
            <a-form :form="form">
              <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_name">
                <a-input
                  v-decorator="['userInfoArray.userName', {initialValue:userInfoArray.userName,rules:[{required:true,message:globalDataArray.siteTemplateLanguage.person_form_please_user_name}]}]"
                />
              </a-form-item>

              <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_email">
                <a-input
                  v-decorator="['userInfoArray.userMail',{initialValue:userInfoArray.userMail,rules:[{required:true,message:globalDataArray.siteTemplateLanguage.team_apply_user_email}]}]"
                />
              </a-form-item>

              <div class="item">
                <a-form-item :label="globalDataArray.siteTemplateLanguage.person_form_country">
                  <a-select :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body;}" @change="handleProvinceChange" :label-in-value="true" v-decorator="['userInfoArray.countryCode', { initialValue:userInfoArray.countryCode, rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_country }] }]">
                    <a-select-option v-for="prov in provinceList" :key="prov.parentId">
                      {{ prov.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_city">
                  <a-select :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body;}" :label-in-value="true" v-decorator="['userInfoArray.cityCode', { initialValue:userInfoArray.cityCode }]">
                    <a-select-option v-for="city in cityList" :key="city.parentId">
                      {{ city.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
            </a-form>
          </div>
        </div>

        <div class="cont" v-if="editTemplate == '02' && editNation == '02'">
          <div class="stform">
            <a-form :form="form">
              <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_name">
                <a-input
                  v-decorator="['userInfoArray.userName', {initialValue:userInfoArray.userName,rules:[{required:true,message:globalDataArray.siteTemplateLanguage.person_form_please_user_name}]}]"
                />
              </a-form-item>

              <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_school">
                <a-input
                  v-decorator="['userInfoArray.company',{initialValue:userInfoArray.company, rules:[{required:true,message:globalDataArray.siteTemplateLanguage.person_please_form_school_name}]}]"
                />
              </a-form-item>

              <div class="item">
                <a-form-item :label="globalDataArray.siteTemplateLanguage.person_form_country">
                  <a-select :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body;}" @change="handleProvinceChange" :label-in-value="true" v-decorator="['userInfoArray.countryCode', { initialValue:userInfoArray.countryCode, rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_country }] }]">
                    <a-select-option v-for="prov in provinceList" :key="prov.parentId">
                      {{ prov.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_city">
                  <a-select :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body;}" :label-in-value="true" v-decorator="['userInfoArray.cityCode', { initialValue:userInfoArray.cityCode }]">
                    <a-select-option v-for="city in cityList" :key="city.parentId">
                      {{ city.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
            </a-form>
          </div>
        </div>
        <div class="btnBox fnt_18">
          <div class="btn btn1" @click="confirmEdit">{{ globalDataArray.siteTemplateLanguage.save }}</div>
          <div class="btn btn2" @click="cancelEdit">{{ globalDataArray.siteTemplateLanguage.cancel }}</div>
        </div>
        <div class="close" @click="cancelEdit"></div>
      </div>
    </div>
    <!-- 编辑成员信息弹窗 -->
  </div>
</template>
<script>
import { teamRecord, selectOneTeamRecord, deleteTeamRecord, updateTeamRecord } from '@/api/person/person'
import { getCompetitionDict, getProvinceList, getCityList } from '@/api/api'
import { Modal, message } from 'ant-design-vue'
import Cookies from 'js-cookie'
export default {
  name: 'Personedit',
  props: {
    personedit: {
      type: Boolean,
      default: false
    },
    curid: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      competitionId: '',
      parentId: '',
      memberId: '',
      userMail: '',
      teamName: '',
      currentTime: '', // 当前地区时区时间时间戳
      columns: [
        {
          scopedSlots: { customRender: 'one' }
        },
        {
          scopedSlots: { customRender: 'two' }
        },
        {
          scopedSlots: { customRender: 'three' }
        },
        {
          scopedSlots: { customRender: 'four' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      list: [],

      form: this.$form.createForm(this, { name: 'dynamic_rule' }),
      showEditpop: false,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      userInfoArray: {
        userName: '',
        userPhone: '',
        userMail: '',
        companyType: '',
        company: '',
        jobType: '',
        countryCode: '',
        cityCode: '',
        competitionId: '',
        parentId: ''
      },
      provinceList: {},
      cityList: {},
      // teamName: '',
      teamType: '',
      teamCreateTime: '',
      isEditOrDelete: 0,
      jobType: [],
      companyType: [],
      current: 1,
      total: 0,
      pageSize: 10,
      nation: '',
      templateId: '',

      leaderData: {}, // 处理青少年的leader数据
      curLang: '',

      editTemplate: '',
      editNation: ''
    }
  },
  watch: {
    personedit: {
      immediate: true,
      handler(val) {
        if (val) {
          // console.log(this.curid)
          this.competitionId = this.curid.competitionParentId
          this.parentId = this.curid.parentId
          this.memberId = this.curid.memberId
          this.teamName = this.curid.teamName
          this.currentTime = new Date().getTime() // 当前地区时区时间时间戳
          this.teamRecord()
          // console.log(val,'111dsdsd')
          // 获取团队成员信息
          //
          const that = this
          const currentLanguge = Cookies.get('DEFAULT_LANG')
          if (currentLanguge != null && currentLanguge != undefined) {
            that.curLang = currentLanguge
          } else {
            that.curLang = that.globalDataArray.siteLanguage[0].value
          }
        }
      }
    }
  },
  mounted() {
    this.getCompetitionDict()

    this.getCityList()
  },
  methods: {
    teamRecord() {
      const that = this
      const params = 'competitionId=' + that.competitionId + '&parentId=' + that.parentId + '&page=' + that.current + '&pageSize=' + that.pageSize
      // let param="page="+that.paginationOpt.current+"&pageSize="+that.paginationOpt.defaultPageSize
      teamRecord(params).then(res => {
        if (res.success) {
          that.list = res.result.records
          that.total = res.result.total
          that.current = res.result.current
          if (that.list.length > 0) {
            that.teamName = that.list[0].teamName
            that.teamType = that.list[0].teamType_dictText
            that.teamCreateTime = that.list[0].createTime
            that.isEditOrDelete = that.list[0].isEditOrDelete

            that.nation = res.result.records[0].nation // 01国内02国外
            that.templateId = res.result.records[0].templateId // 01成人02青少年

            if (that.list[0].templateId == '02') {
              const list = that.list

              that.leaderData['isleader'] = true
              if (that.list[0].leaderName && that.list[0].leaderName != '') {
                that.leaderData['leaderName'] = that.list[0].leaderName
              }
              if (that.list[0].userMail && that.list[0].userMail != '') {
                that.leaderData['userMail'] = that.list[0].userMail
              }
              if (that.list[0].userPhone && that.list[0].userPhone != '') {
                that.leaderData['userPhone'] = that.list[0].userPhone
              }

              const leaderArr = []
              leaderArr.push(that.leaderData)
              list.forEach((item, index) => {
                item['isleader'] = false
                leaderArr.push(item)
              })
              that.list = leaderArr
              // console.log(that.list)
            }

            // 初始化省或国家列表
            this.getProvinceList()
          }
          // console.log(that.list,'list')
        }
      })
    },

    getCompetitionDict() {
      const that = this
      const params = 'dictCode=competition_signup_job_type,competition_signup_company_type'
      getCompetitionDict(params).then(res => {
        if (res.success) {
          const result = res.result
          for (var i = 0; i < result.length; i++) {
            if (result[i].dictCode == 'competition_signup_company_type') {
              that.companyType = result[i].list
            } else {
              that.jobType = result[i].list
            }
          }
        }
      })
    },
    getProvinceList() {
      const that = this

      getProvinceList().then(res => {
        if (res.success) {
          // let result = res.result
          that.provinceList = res.result
          // console.log(result,'area')
        }
      })
    },
    getCityList(pid, dis) {
      const that = this
      const cityParams = 'pid=' + pid
      if (dis) {
        that.userInfoArray.cityCode = ''
        that.userInfoArray.cityName = ''
      }
      getCityList(cityParams).then(res => {
        if (res.success) {
          that.cityList = res.result
        }
      })
    },
    editFun(id) {
      const that = this
      const params = 'id=' + id
      selectOneTeamRecord(params).then(res => {
        if (res.success) {
          that.editTemplate = res.result.template_id
          that.editNation = res.result.nation

          that.showEditpop = true
          that.userInfoArray.userName = res.result.user_name
          that.userInfoArray.userPhone = res.result.user_phone
          that.userInfoArray.userMail = res.result.user_mail
          that.userInfoArray.companyType = res.result.company_type
          that.userInfoArray.company = res.result.company
          that.userInfoArray.jobType = res.result.job_type
          that.userInfoArray.countryCode = res.result.country_code
          that.userInfoArray.cityCode = res.result.city_code
          that.userInfoArray.competitionId = res.result.competition_id
          that.userInfoArray.id = res.result.id

          // that.nation = res.result.nation //01国内02国外
          // that.templateId = res.result.template_id  //01成人02青少年

          setTimeout(() => {
            // 数据回填
            const ccCode = {
              key: res.result.country_code,
              label: res.result.country_name
            }

            let ctCode = ''
            if (res.result.city_code != '' && res.result.city_code != null) {
              ctCode = {
                key: res.result.city_code,
                label: res.result.city_name
              }
            }

            this.form.setFieldsValue({
              'userInfoArray.countryCode': ccCode,
              'userInfoArray.cityCode': ctCode
            })

            // console.log(this.form.getFieldValue('userInfoArray'))
          })

          that.getCityList(that.userInfoArray.countryCode)
        }
      })
    },
    turnPageFun(e) {
      const that = this
      that.current = e
      that.teamRecord()
    },
    cancelEdit() {
      const that = this
      that.showEditpop = false
    },

    confirmEdit() {
      const that = this
      that.form.validateFields((err, val) => {
        // console.log(val)
        if (!err) {
          val.userInfoArray.competitionId = that.userInfoArray.competitionId
          val.userInfoArray.id = that.userInfoArray.id

          if (val.userInfoArray.countryCode != '' && val.userInfoArray.countryCode != undefined) {
            const countryKL = val.userInfoArray.countryCode
            if (countryKL.key) {
              val.userInfoArray['countryCode'] = countryKL.key
            }
            if (countryKL.label) {
              val.userInfoArray['countryName'] = countryKL.label
            }
          }

          if (val.userInfoArray.cityCode != '' && val.userInfoArray.cityCode != undefined) {
            const cityKL = val.userInfoArray.cityCode
            if (cityKL.key) {
              val.userInfoArray['cityCode'] = cityKL.key
            }
            if (cityKL.label) {
              val.userInfoArray['cityName'] = cityKL.label
            }
          } else {
            val.userInfoArray['cityCode'] = ''
            val.userInfoArray['cityName'] = ''
          }

          const params = val.userInfoArray
          // console.log(params)
          updateTeamRecord(params).then(res => {
            if (res.success) {
              that.showEditpop = false
              message.success(res.result)
              that.teamRecord()
            } else {
              message.error(res.result)
            }
          })

          that.showEditpop = false
        }
      })
    },

    delFun(id, competitionId, memberId, userMail, teamName) {
      // console.log(id,competitionId,memberId,userMail,teamName,'del')
      const that = this
      Modal.confirm({
        title: that.globalDataArray.siteTemplateLanguage.person_delete_user,
        content: '',
        okText: that.globalDataArray.siteTemplateLanguage.verify,
        cancelText: that.globalDataArray.siteTemplateLanguage.cancel,
        onOk() {
          const params = {
            'id': id,
            'memberId': memberId,
            'userMail': userMail,
            'competitionId': competitionId,
            'teamName': teamName
          }
          deleteTeamRecord(params).then(res => {
            if (res.success) {
              message.success(res.result)
              that.teamRecord()
            } else {
              message.error(res.result)
            }
          })
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },

    // 测试变化是获取值公共方法，不参功能
    onChange(e) {
      // console.log(e.target.value)
    },

    // 省市联动
    handleProvinceChange(ind) {
      const that = this

      that.getCityList(ind.key, true)

      setTimeout(() => {
        // 选择后清空二级
        this.form.setFieldsValue({
          'userInfoArray.cityCode': ''
        })
      })
    },

    closePop() {
      this.$emit('update:personedit', false)
    }
  }
}
</script>
