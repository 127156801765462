export default {
  bind: function(el, binding) {
    const { value } = binding
    let str = ''
    const textArr = el.innerHTML.split(value)
    textArr.forEach((item, index) => {
      if (index < textArr.length - 1) {
        str = str + item + `<span class="onon">${value}</span>`
      } else {
        str = str + item
      }
    })
    el.innerHTML = str
  }
}
