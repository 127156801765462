<template>
  <div class="formapply" v-if="formapply">
    <!-- 选择报名表单弹窗 -->
    <div class="pagePopMask pFull" @click.self="closePop"></div>
    <div class="pagePop pCenter">
      <div class="w">
        <div class="tit fnt_32 relative">{{ globalDataArray.siteTemplateLanguage.apply_team }}</div>

        <div class="cont">
          <div class="popteamlist">
            <div class="searchteam cf fnt_16">
              <div class="btn fr" @click="search">&nbsp;</div>
              <div class="ovh" @click="teamList()"><a-input v-model="s" :placeholder="globalDataArray.siteTemplateLanguage.enter_join_team" @keyup.enter="search" /></div>
            </div>

            <div class="listHead fnt_16 cf">
              <div class="fl">{{ globalDataArray.siteTemplateLanguage.team_name }}</div>
              <div class="fr">{{ globalDataArray.siteTemplateLanguage.person_table_controls }}</div>
            </div>

            <div class="listBody">
              <div class="item cf" v-for="(item, index) in data" :key="index">
                <div class="name fl">{{ item.teamName }}</div>
                <div class="btn fr" v-if="item.isCaptain=='1'" @click="applyJoin(item)">{{ globalDataArray.siteTemplateLanguage.apply_join }}</div>
                <div class="yiman fr" v-if="item.isCaptain=='0'">{{ globalDataArray.siteTemplateLanguage.apply_yiman }}</div>
                <div class="type ovh">{{ globalDataArray.siteTemplateLanguage.team_type }}：{{ item.teamType_dictText }}</div>
              </div>
            </div>

            <div class="p_page">
              <a-pagination v-model="currentpage" :total="total" :page-size="pageSize" @change="onChange" show-less-items />
            </div>
          </div>
        </div>

        <div class="close" @click.self="closePop"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { teamList } from '@/api/saiti'
export default {
  name: 'Formapply',
  props: {
    formapply: {
      type: Boolean,
      default: false
    },
    curInfoArray: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: [],
      currentpage: 1,
      pageSize: 10,
      total: 0,
      s: ''
    }
  },
  watch: {
    formapply: {
      immediate: true,
      handler(val) {
        if (val) {
          const that = this
          this.teamList()
        }
      }
    }
  },
  methods: {
    teamList() {
      const that = this
      // console.log(this.curInfoArray,'dsds')
      // 获取团队成员列表
      const Param = 'competitionId=' + that.curInfoArray.competitionId + '&page=' + that.currentpage + '&pageSize=' + that.pageSize + '&s=' + that.s
      teamList(Param).then(res => {
        if (res.success) {
          that.data = res.result.records
          that.total = res.result.total
        }
      })
    },
    search() {
      this.currentpage = 1
      this.teamList()
    },
    onChange(e) {
      this.currentpage = e
      this.teamList()
    },
    applyJoin(item) {
      item.clickType = 1
      // 自定义事件，把res传给父组件,发射事件
      this.$emit('itemclick', item) // itemclick  为事件名
      this.$emit('update:formapply', false)
    },

    closePop() {
      this.$emit('update:formapply', false)
    }

  }
}
</script>
