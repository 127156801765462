/* 会员中心接口*/
import { axios } from '@/utils/http'

// 我的报名
export const SignupRecordList = (params) => axios({
  method: 'GET',
  hastoken: true,
  contentType: 'urlencoded'
}, '/member/signup/record?' + params)

// 团队成员
export const teamRecord = (params) => axios({
  method: 'GET',
  hastoken: true,
  contentType: 'urlencoded'
}, '/member/signup/teamRecord?' + params)

// 查询单个团队成员
export const selectOneTeamRecord = (params) => axios({
  method: 'GET',
  hastoken: true,
  contentType: 'urlencoded'
}, '/member/signup/selectOneTeamRecord?' + params)

// 查询单个团队成员
export const updateTeamRecord = (params) => axios({
  method: 'POST',
  data: {
    ...params
  },
  noToast: true,
  hastoken: true,
  contentType: 'json'
}, '/member/signup/updateTeamRecord')

// 删除团队成员
export const deleteTeamRecord = (params) => axios({
  method: 'POST',
  contentType: 'json',
  data: {
    ...params
  },
  noToast: true,
  hastoken: true
}, '/member/signup/deleteTeamRecord')

// 我的审核
export const examineRecord = (params) => axios({
  method: 'GET',
  hastoken: true,
  contentType: 'urlencoded'
}, '/member/examine/record?' + params)

// 审核报名
export const auditRecord = (params) => axios({
  method: 'POST',
  contentType: 'json',
  data: {
    ...params
  },
  noToast: true,
  hastoken: true
}, '/member/apply/auditRecord')

// 我的申请
export const applyRecord = (params) => axios({
  method: 'GET',
  hastoken: true,
  contentType: 'urlencoded'
}, '/member/apply/record?' + params)

// 撤回申请
export const cancelRecord = (params) => axios({
  method: 'GET',
  hastoken: true,
  contentType: 'urlencoded'
}, '/member/apply/cancelRecord?' + params)

// 消息管理
export const MessageRecordList = (params) => axios({
  method: 'GET',
  hastoken: true,
  contentType: 'urlencoded'
}, '/member/message/record?' + params)

// 消息已读
export const MessageRead = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded',
  hastoken: true
}, '/member/message/read?' + params)

// 删除消息
export const deleteMessageRecord = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded',
  hastoken: true
}, '/member/message/delRecord?' + params)

// 老用户赛题列表标记已阅读协议
export const readUserAgreement = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded',
  hastoken: true
}, '/member/agreement/read')
