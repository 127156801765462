<template>
  <div>
    <div class="tit" :class="curLang == 'rus' ? 'fnt_24' : 'fnt_32'">{{ globalDataArray.siteTemplateLanguage.person_user_center }}</div>
    <div class="personMenu" :class="curLang == 'rus' ? 'fnt_16' : 'fnt_20'">
      <div class="item" v-for="(item,index) in menu" :key="'menu' + index" @click="gopage(item.path)" :class="item.path == isSelect ? 'cur' : ''">
        <img :src="item.icon">{{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  name: 'Personmenu',
  data() {
    return {
      menu: [{
        id: '1',
        name: this.globalDataArray.siteTemplateLanguage.person_signup_record,
        icon: `${require('@/assets/images/person1.png')}`,
        path: '/myrecord'
      },
      {
        id: '2',
        name: this.globalDataArray.siteTemplateLanguage.person_review_record,
        icon: `${require('@/assets/images/person2.png')}`,
        path: '/myexamine'
      },
      {
        id: '3',
        name: this.globalDataArray.siteTemplateLanguage.person_apply_record,
        icon: `${require('@/assets/images/person3.png')}`,
        path: '/myapply'
      },
      {
        id: '4',
        name: this.globalDataArray.siteTemplateLanguage.person_message_record,
        icon: `${require('@/assets/images/person4.png')}`,
        path: '/mymessage'
      }],
      isSelect: '/myrecord',
      curLang: ''
    }
  },
  mounted() {
    this.isSelect = this.$route.path

    const that = this
    const currentLanguge = Cookies.get('DEFAULT_LANG')
    if (currentLanguge != null && currentLanguge != undefined) {
      that.curLang = currentLanguge
    } else {
      that.curLang = that.globalDataArray.siteLanguage[0].value
    }
  },
  methods: {
    gopage(path) {
      if (this.isSelect != path) {
        this.$router.push(path)
        this.isSelect = path
      }
    }
  }
}
</script>
