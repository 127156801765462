<template>
  <div class="swiperBox" v-swiper:dySwiper="danweiOption">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-if="unitList.length>0">
        <div class="indTit relative">
          <div class="f1 fnt_60"> {{ globalDataArray.siteTemplateLanguage.partners_title_01 }} </div>
          <div class="f2 fnt_28">{{ globalDataArray.siteTemplateLanguage.partners_desc }}</div>
        </div>

        <div class="rowBox" v-for="indx of unitList.length" :key="'row' + indx">
          <div class="row">
            <div class="pic tran_scale" v-for="(item,index) of unitList[(indx-1)]" :key="'scale' + index">
              <router-link v-if="item.linkUrl!=''&&item.linkUrl!=null" :to="item.linkUrl">
                <img :src="item.image" alt="">
              </router-link>

              <router-link v-else to="/">
                <img :src="item.image" alt="">
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide" v-if="false">
        <!--隐藏合作单位-->
        <div class="indTit relative">
          <div class="f1 fnt_60"> {{ globalDataArray.siteTemplateLanguage.partners_title_02 }} </div>
          <div class="f2 fnt_28">{{ globalDataArray.siteTemplateLanguage.partners_desc }}</div>
        </div>
        <div class="rowBox" v-for="indx of partnerList.length" :key="'row' + indx">
          <div class="row">
            <div class="pic tran_scale" v-for="(item,index) of partnerList[(indx-1)]" :key="'scale2' + index">
              <router-link v-if="item.linkUrl!=''&&item.linkUrl!=null" :to="item.linkUrl">
                <img :src="item.image" alt="">
              </router-link>

              <router-link v-else to="/">
                <img :src="item.image" alt="">
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide" v-if="mediumList.length>0">
        <div class="indTit relative">
          <div class="f1 fnt_60"> {{ globalDataArray.siteTemplateLanguage.partners_title_03 }} </div>
          <div class="f2 fnt_28">{{ globalDataArray.siteTemplateLanguage.partners_desc }}</div>
        </div>
        <div class="rowBox" v-for="indx of mediumList.length" :key="'row' + indx">
          <div class="row">
            <div class="pic tran_scale" v-for="(item,index) of mediumList[(indx-1)]" :key="'scale3' + index">
              <router-link v-if="item.linkUrl!=''&&item.linkUrl!=null" :to="item.linkUrl">
                <img :src="item.image" alt="">
              </router-link>

              <router-link v-else to="/">
                <img :src="item.image" alt="">
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination swiper-pagination-bullets sPage2" id="sPage2"></div>
  </div>
</template>

<script>
import { getIndexInfo } from '@/api/api'
export default {
  name: 'Unitswiper',
  data() {
    return {
      danweiOption: {
        autoplay: 15000,
        // loop: true,
        pagination: '.sPage2',
        paginationClickable: true
      },
      unitList: [], // 共建单位
      partnerList: [], // 合作伙伴
      mediumList: [] // 合作媒体
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      const that = this
      getIndexInfo().then(res => {
        if (res.success) {
          const result = res.result
          that.unitList = that.splitArray(result.unitList)
          that.partnerList = that.splitArray(result.partnerList)
          that.mediumList = that.splitArray(result.mediumList)
          that.$nextTick(() => {
            if (typeof document === 'object') {
              if (that.unitList != '' || that.partnerList != '' || that.mediumList != '') {
                document.getElementById('sPage2').childNodes[0].classList.add('swiper-pagination-bullet-active')
              }
            }
          })
        }
      })
    },

    splitArray(arr) {
      const result = []
      const length = arr.length
      for (let i = 0; i < length; i += 6) {
        const temp = arr.slice(i, i + 6)
        result.push(temp)
      }
      return result
    }
  }
}
</script>
