<template>
  <div class="olduserxieyi" v-if="olduserxieyi">
    <!-- 选择报名表单弹窗 -->
    <div class="pagePopMask pFull"></div>
    <div class="pagePop pCenter">
      <div class="w">
        <div class="tit fnt_32 relative">{{ content.title }}</div>

        <div class="cont txtCont scrollable" v-html="content.content" @scroll="handleScroll">
        </div>

        <div class="btnBox fnt_18 btnBox2">
          <a-checkbox :disabled="true" v-if="disabled||!readed" />
          <a-checkbox :checked="checked" @change="onChange" v-else />
          <button class="btn btn1" :class="{disabled : counter > 0}" :disabled="counter > 0" @click="agreeFun('1')">{{ globalDataArray.siteTemplateLanguage.old_user_read }}<span v-if="counter > 0">({{ counter }}s)</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { getArticleDetail } from '@/api/api'
import { readUserAgreement } from '@/api/person/person'
import Cookies from 'js-cookie'
export default {
  name: 'Olduserxieyi',
  props: {
    olduserxieyi: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      counter: 0,
      interval: null,

      checked: false,
      disabled: true,
      content: {},
      readed: false,
      theEnd: true,
      articleid: '1705132559188664321' // 文章用户协议id
    }
  },
  watch: {
    olduserxieyi: {
      immediate: true,
      handler(val) {
        if (val) {
          const that = this
          this.checked = false
          this.readed = false
          this.theEnd = true
          this.disabled = true

          // 获取协议信息
          const params = 'id=' + that.articleid
          getArticleDetail(params).then(res => {
            if (res.success) {
              that.content = res.result
              this.counterFun()
            }
          })
        }
      }
    }
  },
  computed: {

  },
  methods: {
    onChange(e) {
      this.checked = e.target.checked
    },
    // 同意协议，把会员表的is_agree 改成1 已阅读同步修改cookie里的isAgree
    agreeFun(res) {
      // 自定义事件，把res传给父组件,发射事件
      if (this.checked) {
        // 清除cookie
        const saitiAccessInfo = JSON.parse(Cookies.get('saiti_access_info'))
        saitiAccessInfo.isAgree = 1
        Cookies.set('saiti_access_info', JSON.stringify(saitiAccessInfo), { domain: process.env.NODE_ENV === 'production' ? '.atomgit.com' : undefined, expires: 180, path: '/', secure: process.env.NODE_ENV === 'production' })
        // end
        // 修改数据库表字段
        readUserAgreement('')

        // end
        this.$emit('itemclick', res) // itemclick  为事件名
        this.$emit('update:olduserxieyi', false)
      } else {
        message.error(this.globalDataArray.siteTemplateLanguage.msg_no_read)
      }
    },

    closePop() {
      this.$emit('update:olduserxieyi', false)
      if (this.interval != null) {
        clearInterval(this.interval)
      }
    },

    counterFun() {
      const that = this
      if (!that.checked) {
        that.counter = 5
        that.interval = setInterval(() => {
          that.counter--
          if (that.counter <= 0) {
            this.disabled = !this.disabled
            clearInterval(that.interval)
          }
        }, 1000)
      }
    },

    handleScroll() {
      if (typeof document === 'object') {
        const container = document.querySelector('.scrollable')
        const { scrollTop, scrollHeight, clientHeight } = container
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          this.readed = true
          if (this.theEnd) {
            this.theEnd = false
            message.success(this.globalDataArray.siteTemplateLanguage.to_the_end)
          }
        }
      }
    }

  }
}
</script>
