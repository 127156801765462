<template>
  <div class="stform personexamine" v-if="personexamine">
    <div class="pagePopMask pFull" @click.self="closePop"></div>
    <div class="pagePop w1280 pCenter">
      <div class="w">
        <div class="tit fnt_32">{{ globalDataArray.siteTemplateLanguage.join_team_audit }}</div>

        <div class="cont">
          <div class="compCommonInfo fnt_18">
            <span class="w50">{{ globalDataArray.siteTemplateLanguage.team_name }}：{{ datainfo.teamName }}</span>
            <span class="w50">{{ globalDataArray.siteTemplateLanguage.team_type }}：{{ datainfo.teamType_dictText }}</span>
          </div>

          <div class="pad_0_16">
            <!-- 国内成人 -->
            <template v-if="templateId == '01' && nation == '01'">
              <div class="examinePopList fnt_18">
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_name }}：</span>{{ datainfo.userName }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_phone }}：</span>{{ datainfo.userPhone }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_email }}：</span>{{ datainfo.userMail }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_company_type }}：</span>{{ datainfo.companyType_dictText }}</div>
                <div class="exaitem">
                  <span class="c-gray" v-if="datainfo.companyType=='02'">{{ globalDataArray.siteTemplateLanguage.team_member_school }}：</span>
                  <span class="c-gray" v-else>{{ globalDataArray.siteTemplateLanguage.team_user_company_name }}：</span>
                  {{ datainfo.company }}
                </div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_job_type }}：</span>{{ datainfo.jobType_dictText }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_city }}：</span>{{ datainfo.countryName }}{{ datainfo.cityName }}</div>
              </div>
            </template>

            <!-- 国外成人 -->
            <template v-if="templateId == '01' && nation == '02'">
              <div class="examinePopList fnt_18">
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_name }}：</span>{{ datainfo.userName }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_email }}：</span>{{ datainfo.userMail }}</div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.person_form_country }}：</span>{{ datainfo.countryName }}{{ datainfo.cityName }}</div>
              </div>
            </template>

            <!-- 国内青少年 -->
            <template v-if="templateId == '02' && nation == '01'">
              <div class="examinePopList fnt_18">
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_name }}：</span>{{ datainfo.userName }}</div>
                <div class="exaitem">
                  <span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_school }}：</span>
                  {{ datainfo.company }}
                </div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_user_city }}：</span>{{ datainfo.countryName }}{{ datainfo.cityName }}</div>
              </div>
            </template>

            <!-- 国外青少年 -->
            <template v-if="templateId == '02' && nation == '02'">
              <div class="examinePopList fnt_18">
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_name }}：</span>{{ datainfo.userName }}</div>
                <div class="exaitem">
                  <span class="c-gray">{{ globalDataArray.siteTemplateLanguage.team_member_school }}：</span>
                  {{ datainfo.company }}
                </div>
                <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.person_form_country }}：</span>{{ datainfo.countryName }}{{ datainfo.cityName }}</div>
              </div>
            </template>
          </div>
        </div>

        <div class="btnBox fnt_18" v-if="isExam">
          <div class="btn btn1" @click="btnClick(1)">{{ globalDataArray.siteTemplateLanguage.audit_yes }}</div>
          <div class="btn btn3" @click="btnClick(0)">{{ globalDataArray.siteTemplateLanguage.audit_no }}</div>
        </div>
        <div class="close" @click.self="closePop"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { auditRecord } from '@/api/person/person'
import { Modal, message } from 'ant-design-vue'
export default {
  name: 'Personexamine',
  props: {
    personexamine: {
      type: Boolean,
      default: false
    },
    curid: {
      type: Object,
      default: () => ({})
    },
    isExam: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      datainfo: {
        teamName: '',
        teamType: '',
        userName: '',
        userPhone: '',
        userMail: '',
        companyType: '',
        company: '',
        jobType: '',
        countryName: '',
        countryCode: '',
        cityName: '',
        cityCode: '',
        templateId: '',
        nation: ''
      }
    }
  },
  watch: {
    curid: {
      immediate: true,
      handler(record) {
        if (record != '') {
          this.examineRecord(record)
          // 获取成员信息
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    examineRecord(record) {
      this.datainfo = record
      this.templateId = record.templateId
      this.nation = record.nation
      // console.log(record,'rerere')
    },
    auditRecord(id, checkStatus, checkRemark) {
      const that = this
      const params = {
        'id': id,
        'checkStatus': checkStatus,
        'memberId': that.datainfo.memberId,
        'userMail': that.datainfo.userMail,
        'competitionId': that.datainfo.competitionParentId,
        'teamName': that.datainfo.teamName,
        'checkRemark': checkRemark
      }
      auditRecord(params).then(res => {
        if (res.success) {
          message.success(res.result)
          that.$emit('itemclick', res) // itemclick  为事件名
          that.$emit('update:personexamine', false)
        } else {
          message.error(res.message)
        }
      })
    },
    btnClick(res) {
      const that = this
      if (res == '1') {
        Modal.confirm({
          title: that.globalDataArray.siteTemplateLanguage.person_audit_yes_sign_up,
          content: '',
          okText: that.globalDataArray.siteTemplateLanguage.verify,
          cancelText: that.globalDataArray.siteTemplateLanguage.cancel,
          onOk() {
            that.auditRecord(that.datainfo.id, '03', that.globalDataArray.siteTemplateLanguage.audit_pass)
            // console.log(that.datainfo,'OK');
            // 自定义事件，把res传给父组件,发射事件
            // that.$emit('itemclick', res)   //itemclick  为事件名
            // that.$emit('update:personexamine', false)
          },
          onCancel() {
            console.log('Cancel')
          }
        })
      } else if (res == '0') {
        Modal.confirm({
          title: that.globalDataArray.siteTemplateLanguage.person_audit_no_sign_up,
          content: '',
          okText: that.globalDataArray.siteTemplateLanguage.verify,
          cancelText: that.globalDataArray.siteTemplateLanguage.cancel,
          onOk() {
            // console.log('OK');
            that.auditRecord(that.datainfo.id, '04', that.globalDataArray.siteTemplateLanguage.audit_no_pass)
            // 自定义事件，把res传给父组件,发射事件
            // that.$emit('itemclick', res)   //itemclick  为事件名
            // that.$emit('update:personexamine', false)
          },
          onCancel() {
            console.log('Cancel')
          }
        })
      }
    },
    closePop() {
      this.$emit('update:personexamine', false)
    }
  }
}
</script>
