import { httpPost, httpGet } from '@/utils/http'

class sapi {
  static getSaitiInfo(data, language) {
    // return Promise.resolve('res')
    return httpGet('/competition/detail', data, language)
  }

  static getNewsInfo(data, language) {
    return httpGet('/news/getDetails', data, language)
  }
}

export default sapi
