<template>
  <div class="stform personmsg" v-if="personmsg">
    <div class="pagePopMask pFull" @click.self="closePop"></div>
    <div class="pagePop w1280 pCenter">
      <div class="w">
        <div class="cont">
          <div class="tit fnt_32">{{ datainfo.title }}</div>

          <div class="examinePopList fnt_18">
            <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.msg_publisher }}：</span>{{ datainfo.sender }}</div>
            <div class="exaitem"><span class="c-gray">{{ globalDataArray.siteTemplateLanguage.publish_time }}：</span>{{ datainfo.createTime }}</div>
          </div>

          <div class="fnt_18 mymsgcont" v-html="datainfo.msgContent"></div>
        </div>
        <div class="close" @click.self="closePop"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageRead } from '@/api/person/person'
export default {
  name: 'Personmsg',
  props: {
    personmsg: {
      type: Boolean,
      default: false
    },
    curid: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      datainfo: {
        id: '',
        title: '',
        sender: '',
        createTime: '',
        content: ''
      }
    }
  },
  watch: {
    personmsg: {
      immediate: true,
      handler(val) {
        if (val) {
          this.datainfo = this.curid
          this.MessageRead()
          // 获取信息
        }
      }
    }
  },
  methods: {
    MessageRead() {
      const that = this
      const param = 'id=' + this.datainfo.id
      // 消息已读标记
      MessageRead(param).then(res => {
        if (res.success) {

        }
      })
    },
    closePop() {
      this.$emit('update:personmsg', false)
    }
  }
}
</script>
