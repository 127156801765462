/* 赛事接口*/
import { axios } from '@/utils/http'

// 赛事分类
export const getCategoryList = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/competition/category')

export const getCompetitionList = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/competition/list?' + params)

export const getCompetitionListForZone = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/competition/zone/competitions?' + params)

export const getCompetitionInfo = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/competition/detail?' + params)

// 提交报名接口
export const competitionSub = (params) => axios({
  method: 'POST',
  contentType: 'jsonArray',
  data: params,
  noToast: true,
  hastoken: true
}, 'competition/signup/submit')

// 年级组别
export const getGroupList = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/competition/signup/group')

export const previewStInfo = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/competition/preview/detail?' + params)

// 获取赛题团队列表
export const teamList = (params) => axios({
  method: 'GET',
  noToast: true,
  hastoken: true
}, 'competition/signup/team?' + params)

// 申请加入团队
export const joinTeam = (params) => axios({
  method: 'POST',
  contentType: 'jsonArray',
  data: params,
  noToast: true,
  hastoken: true
}, 'competition/signup/apply')

export const uploadFile = (params) => axios({
  method: 'POST',
  contentType: 'formData',
  data: params,
  noToast: true,
  hastoken: true
}, 'competition/file/uploadFile')

export const getTechnicalList = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/competition/technical')
