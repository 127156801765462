<template>
  <div>
    <div class="formsign" v-if="formsign && !showXuzhi">
      <!-- 选择报名表单弹窗 -->
      <div class="pagePopMask pFull" @click.self="closePop"></div>
      <div class="pagePop pCenter">
        <div class="w pad60">
          <div class="tit fnt_24">
            {{ globalDataArray.siteTemplateLanguage.to_sign_up }} <div
              class="xuzhiBtn"
              @click="targetXuzhi"
            >
              {{ globalDataArray.siteTemplateLanguage.join_notice }}
            </div>
          </div>

          <div class="cont">
            <div class="stform">
              <a-form :form="form" :model="data">
                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_name">
                  <a-input
                    v-decorator="['data.teamName', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.enter_join_team }] }]"
                  />
                </a-form-item>

                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_type">
                  <a-radio-group
                    @change="onChangeTeamType"
                    v-decorator="['data.teamType', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.select_team_type }] }]"
                  >
                    <span class="radioLabelBox" v-for="(item, index) in teamList" :key="index">
                      <a-radio :style="radioStyle" :value="item.itemValue">{{ item.itemText }}</a-radio>
                      <a-tooltip placement="bottom">
                        <a-icon type="question-circle" title="" />
                        <template slot="title">
                          <span v-html="item.tipsmsg"></span>
                        </template>
                      </a-tooltip>
                    </span>
                  </a-radio-group>
                </a-form-item>

                <a-form-item :label="globalDataArray.siteTemplateLanguage.sign_upload_file_label" :extra="globalDataArray.siteTemplateLanguage.sign_upload_file_tips" v-if="teamType === '02'">
                  <a-upload
                    v-decorator="[
                      'data.fileUrl',
                      {
                        getValueFromEvent: normFile,
                        rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.select_file_url }]
                      }
                    ]"
                    name="file"
                    accept=".zip,.rar,.7z"
                    :file-list="fileList"
                    :remove="removeFiles"
                    :before-upload="beforeUpload"
                    :custom-request="customUpload"
                  >
                    <a-button :disabled="uploading" class="my-5">{{ globalDataArray.siteTemplateLanguage.sign_upload_file_button }}</a-button>
                  </a-upload>
                </a-form-item>

                <div class="line"></div>

                <div class="item" v-if="curInfoArray.currentLanguge != 'zh_CN' && curInfoArray.currentLanguge != ''">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_leader">
                    <a-input
                      v-decorator="['data.userName', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.enter_captain_name }] }]"
                    />
                  </a-form-item>
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_email">
                    <a-input
                      v-decorator="['data.userMail', { rules: [{ validator: validateNumber, trigger: 'blur' }, { required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_user_email }] }]"
                    />
                  </a-form-item>
                </div>

                <div class="item" v-if="curInfoArray.currentLanguge == 'zh_CN' || curInfoArray.currentLanguge == ''">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_leader">
                    <a-input
                      v-decorator="['data.userName', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.enter_captain_name }] }]"
                    />
                  </a-form-item>
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_phone">
                    <a-input
                      v-decorator="['data.userPhone', { rules: [{ pattern: /^[0-9-+]+$/, message: globalDataArray.siteTemplateLanguage.tel_format_error }, { required: true, message: globalDataArray.siteTemplateLanguage.enter_member_tel }] }]"
                    />
                  </a-form-item>
                </div>
                <div class="item" v-if="curInfoArray.currentLanguge == 'zh_CN' || curInfoArray.currentLanguge == ''">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_email">
                    <a-input
                      v-decorator="['data.userMail', { rules: [{ validator: validateNumber, trigger: 'blur' }, { required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_user_email }] }]"
                    />
                  </a-form-item>
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_company_type">
                    <a-radio-group
                      @change="onChange"
                      v-decorator="['data.companyType', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_company_type }] }]"
                    >
                      <a-radio
                        :style="radioStyle" v-for="(item, index) in companyList" :value="item.itemValue"
                        :key="index"
                      >
                        {{ item.itemText }}
                      </a-radio>
                    </a-radio-group>
                  </a-form-item>
                </div>

                <a-form-item
                  :label="globalDataArray.siteTemplateLanguage.enter_dw_name"
                  v-if="curInfoArray.currentLanguge == 'zh_CN' || curInfoArray.currentLanguge == ''"
                >
                  <a-input
                    v-decorator="['data.company', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_company_name }] }]"
                  />
                </a-form-item>
                <a-form-item
                  :label="globalDataArray.siteTemplateLanguage.team_user_job_type"
                  v-if="curInfoArray.currentLanguge == 'zh_CN' || curInfoArray.currentLanguge == ''"
                >
                  <a-radio-group @change="onChange" v-decorator="['data.jobType']">
                    <a-radio
                      :style="radioStyle" v-for="(item, index) in jobList" :value="item.itemValue"
                      :key="index"
                    >
                      {{ item.itemText }}
                    </a-radio>
                  </a-radio-group>
                </a-form-item>

                <div class="item">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.person_form_province">
                    <!-- <a-cascader :options="cityList" :defaultValue="defaultArea" placeholder="Please select" @change="onChange" /> -->
                    <a-select
                      :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body; }"
                      @change="handleProvinceChange($event, '-1')" :label-in-value="true"
                      v-decorator="['data.countryCode', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_province }] }]"
                    >
                      <a-select-option v-for="item in provinceList" :key="item.parentId">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_city">
                    <a-select
                      :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body; }"
                      :label-in-value="true" v-decorator="['data.cityCode']"
                    >
                      <a-select-option v-for="i in data.cityList" :key="i.parentId">
                        {{ i.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>

                <!-- 增减团队成员模板 start -->
                <div class="addPrsonTemp" v-for="(k, index) in form.getFieldValue('keys')" :key="k">
                  <div class="line"></div>

                  <div class="item" v-if="curInfoArray.currentLanguge != 'zh_CN' && curInfoArray.currentLanguge != ''">
                    <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_name">
                      <a-input
                        v-decorator="[`temp[${k}].userName`, { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_form_please_user_name }] }]"
                      />
                    </a-form-item>
                    <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_email">
                      <a-input
                        v-decorator="[`temp[${k}].userMail`, { rules: [{ validator: validateNumber, trigger: 'blur' }, { required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_user_email }] }]"
                      />
                    </a-form-item>
                  </div>

                  <div class="item" v-if="curInfoArray.currentLanguge == 'zh_CN' || curInfoArray.currentLanguge == ''">
                    <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_name">
                      <a-input
                        v-decorator="[`temp[${k}].userName`, { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_form_please_user_name }] }]"
                      />
                    </a-form-item>
                    <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_phone">
                      <a-input
                        v-decorator="[`temp[${k}].userPhone`, { rules: [{ pattern: /^[0-9-+]+$/, message: globalDataArray.siteTemplateLanguage.tel_format_error }, { required: true, message: globalDataArray.siteTemplateLanguage.enter_member_tel }] }]"
                      />
                    </a-form-item>
                  </div>

                  <div class="item" v-if="curInfoArray.currentLanguge == 'zh_CN' || curInfoArray.currentLanguge == ''">
                    <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_email">
                      <a-input
                        v-decorator="[`temp[${k}].userMail`, { rules: [{ validator: validateNumber, trigger: 'blur' }, { required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_user_email }] }]"
                      />
                    </a-form-item>
                    <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_company_type">
                      <a-radio-group
                        @change="onChange"
                        v-decorator="[`temp[${k}].companyType`, { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_company_type }] }]"
                      >
                        <a-radio
                          :style="radioStyle" v-for="(item, index1) in companyList" :value="item.itemValue"
                          :key="index1"
                        >
                          {{ item.itemText }}
                        </a-radio>
                      </a-radio-group>
                    </a-form-item>
                  </div>

                  <a-form-item
                    :label="globalDataArray.siteTemplateLanguage.enter_dw_name"
                    v-if="curInfoArray.currentLanguge == 'zh_CN' || curInfoArray.currentLanguge == ''"
                  >
                    <a-input
                      v-decorator="[`temp[${k}].company`, { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_company_name }] }]"
                    />
                  </a-form-item>
                  <a-form-item
                    :label="globalDataArray.siteTemplateLanguage.team_user_job_type"
                    v-if="curInfoArray.currentLanguge == 'zh_CN' || curInfoArray.currentLanguge == ''"
                  >
                    <a-radio-group @change="onChange" v-decorator="[`temp[${k}].jobType`]">
                      <a-radio
                        :style="radioStyle" v-for="(item, index1) in jobList" :value="item.itemValue"
                        :key="index1"
                      >
                        {{ item.itemText }}
                      </a-radio>
                    </a-radio-group>
                  </a-form-item>

                  <div class="item">
                    <a-form-item :label="globalDataArray.siteTemplateLanguage.person_form_province">
                      <a-select
                        :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body; }"
                        @change="handleProvinceChange($event, k)" :label-in-value="true"
                        v-decorator="[`temp[${k}].countryCode`, { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_province }] }]"
                      >
                        <a-select-option v-for="prov in provinceList" :key="prov.parentId">
                          {{ prov.title }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>

                    <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_city">
                      <a-select
                        :get-popup-container="triggerNode => { return triggerNode.parentNode || document.body; }"
                        :label-in-value="true" v-decorator="[`temp[${k}].cityCode`]"
                      >
                        <a-select-option v-for="city in temp[k].cityList" :key="city.parentId">
                          {{ city.title }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>

                  <a-button @click="delPerson(k)">
                    <a-icon type="minus" />
                    {{ globalDataArray.siteTemplateLanguage.del_team_member }}
                  </a-button>
                </div>

                <a-button @click="addPerson()">
                  <a-icon type="plus" />
                  {{ globalDataArray.siteTemplateLanguage.add_team_member }}
                </a-button>
                <!-- 增减团队成员模板 end -->
              </a-form>
            </div>
          </div>

          <div class="btnBox fnt_18">
            <div class="btn btn1" @click="btnClick('1')">{{ globalDataArray.siteTemplateLanguage.right_away_sign }}</div>
            <div class="btn btn2" @click="btnClick('2')">{{ globalDataArray.siteTemplateLanguage.cancel }}</div>
          </div>

          <div class="close" @click.self="closePop"></div>
        </div>
      </div>
    </div>

    <div class="pagePopMask pFull" v-if="showXuzhi" @click="targetXuzhi"></div>
    <div class="pagePop pCenter" v-if="showXuzhi">
      <div class="w pad60">
        <div class="tit fnt_32">{{ xuzhiCont.title }}</div>

        <div class="cont" v-html="xuzhiCont.content">
        </div>
        <div class="btnBox fnt_18">
          <div class="btn btn1" @click="targetXuzhi">{{ globalDataArray.siteTemplateLanguage.verify }}</div>
        </div>
        <div class="close" @click="targetXuzhi"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { getCompetitionDict, getProvinceList, getCityList, getArticle, getArticleDetail } from '@/api/api'
import { competitionSub, uploadFile } from '@/api/saiti'

let id = 0

export default {
  name: 'Formsign',
  props: {
    formsign: {
      type: Boolean,
      default: false
    },
    curInfoArray: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      subSignInfo: true,
      data: {
        teamName: '',
        teamType: '',
        leaderName: '',
        userName: '',
        userPhone: '',
        userMail: '',
        companyType: '',
        company: '',
        jobType: '',
        countryCode: '',
        countryName: '',
        cityCode: '',
        cityName: '',
        cityList: [],
        temp: [],
        fileUrl: ''
      },

      temp: [], // 添加的队员数据模板

      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },

      provinceList: {}, // 公共的省或国家
      companyList: {}, // 公共的单位类型
      jobList: {}, // 公共的职业类型
      teamList: {}, // 公共的团队类型
      cityList: {},
      xuzhiCont: '',
      showXuzhi: false,
      uploading: false,
      teamType: '',
      fileList: []
    }
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'dynamic_form_item' })
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
  },

  mounted() {
  },

  watch: {
    formsign: {
      immediate: true,
      handler(val) {
        if (val) {
          const that = this
          that.temp = []
          this.subSignInfo = true
          // console.log(that.curInfoArray)
          this.xuzhiCont = ''
          this.teamType = ''
          this.fileList = []
          id = 0
          this.form.resetFields()

          that.initDictFun()
        }
      }
    }
  },

  methods: {
    initDictFun() {
      const that = this
      const params = 'dictCode=competition_signup_team_type,competition_signup_job_type,competition_signup_company_type'

      getCompetitionDict(params).then(res => {
        if (res.success) {
          // console.log(res)
          res.result.forEach((item, index) => {
            if (item.dictCode == 'competition_signup_company_type') { // 单位类型
              that.companyList = item.list // 公共的单位类型
            }
            if (item.dictCode == 'competition_signup_job_type') { // 职业类型
              that.jobList = item.list // 公共的职业类型
            }
            if (item.dictCode == 'competition_signup_team_type') { // 团队类型
              // that.teamList=item.list        //公共的团队类型

              const teamList = item.list
              // 两个说明
              getArticle('type=5,6').then(res => {
                if (res.success) {
                  const tipsMsg = res.result

                  teamList.forEach((item, index) => {
                    if (item.itemValue == '01') { // 个人
                      tipsMsg.forEach((e, i) => {
                        if (e.type == '5') {
                          item['tipsmsg'] = e.content
                        }
                      })
                    }

                    if (item.itemValue == '02') { // 公司
                      tipsMsg.forEach((e, i) => {
                        if (e.type == '6') {
                          item['tipsmsg'] = e.content
                        }
                      })
                    }
                  })

                  that.teamList = teamList
                }
              })
            }
          })
        }
      })

      getProvinceList().then(res => {
        if (res.success) {
          that.provinceList = res.result // 省或国家列表
        }
      })
    },

    onChange(e) {
      // console.log(e.target.value)
    },

    addPerson() {
      const temp = this.temp
      const curInfoArray = this.curInfoArray
      const data = this.data
      const max_member_num = this.globalDataArray.siteTemplateLanguage.max_member_num

      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      if (keys.length == curInfoArray.teamsUsers - 1) {
        message.error(max_member_num + curInfoArray.teamsUsers)
        return false
      }

      temp.push(data)

      const nextKeys = keys.concat(id++)

      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys: nextKeys
      })
    },
    delPerson(k) {
      // this.temp.splice(k,1)

      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')

      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter(key => key !== k)
      })
    },

    // 省市联动
    handleProvinceChange(e, ind) {
      // console.log(e,ind)
      const that = this
      const params = 'pid=' + e.key
      getCityList(params).then(res => {
        if (res.success) {
          if (ind == -1) {
            that.data.cityList = res.result
            setTimeout(() => {
              // 选择后清空二级
              this.form.setFieldsValue({
                'data.cityCode': ''
              })
            })
          } else {
            that.temp[ind].cityList = res.result
            setTimeout(() => {
              // 选择后清空二级
              this.form.setFieldsValue({
                [`temp[${ind}].cityCode`]: ''
              })
            })
          }
        }
      })
    },

    btnClick(num) {
      if (num == 1) { // 报名
        if (this.subSignInfo) {
          this.form.validateFields((err, val) => {
            const params = []
            const curInfoArray = this.curInfoArray
            Object.keys(curInfoArray).forEach(key => {
              val.data[key] = curInfoArray[key]
            })
            val.data['isCaptain'] = 1
            val.data['leaderName'] = val.data['userName']

            if (val.data.countryCode != '' && val.data.countryCode != undefined) {
              const countryKL = val.data.countryCode
              val.data['countryCode'] = countryKL.key
              val.data['countryName'] = countryKL.label
            }

            if (val.data.cityCode != '' && val.data.cityCode != undefined) {
              const cityKL = val.data.cityCode
              val.data['cityCode'] = cityKL.key
              val.data['cityName'] = cityKL.label
            } else {
              val.data['cityCode'] = ''
              val.data['cityName'] = ''
            }

            params.push(val.data)

            if (val.temp) {
              val.temp.forEach((item, index) => {
                Object.keys(curInfoArray).forEach(key => {
                  item[key] = curInfoArray[key]
                })
                item['isCaptain'] = 0

                if (item.countryCode != '' && item.countryCode != undefined) {
                  const countryKL = item.countryCode
                  item['countryCode'] = countryKL.key
                  item['countryName'] = countryKL.label
                }

                if (item.cityCode != '' && item.cityCode != undefined) {
                  const cityKL = item.cityCode
                  item['cityCode'] = cityKL.key
                  item['cityName'] = cityKL.label
                } else {
                  item['cityCode'] = ''
                  item['cityName'] = ''
                }

                item['teamName'] = val.data['teamName']
                item['teamType'] = val.data['teamType']
                item['leaderName'] = val.data['userName']

                params.push(item)
              })
            }

            // console.log(params)

            if (!err) {
              this.subSignInfo = false
              competitionSub(params).then(res => {
                this.subSignInfo = true
                if (res.success) {
                  this.$emit('itemclick', num) // itemclick  为事件名
                  this.$emit('update:formsign', false)
                  if (window._oafs) {
                    window._oafs.action('报名成功', {
                      comId: params[0].competitionId
                    })
                  }
                } else {
                  if (res.code == '403') {
                    message.error(res.message)
                  }
                }
              })
            }
          })
        }
      } else {
        // 自定义事件，把num传给父组件,发射事件
        this.$emit('itemclick', num) // itemclick  为事件名
        this.$emit('update:formsign', false)
      }
    },

    closePop() {
      this.$emit('update:formsign', false)
    },

    targetXuzhi() {
      this.teamType = ''
      this.fileList = []
      this.showXuzhi = !this.showXuzhi

      if (this.showXuzhi && this.xuzhiCont == '') {
        const params = 'id=1705132838718054401'
        getArticleDetail(params).then(res => {
          if (res.success) {
            this.xuzhiCont = res.result
          }
        })
      }
    },

    validateNumber(rule, value, callback) {
      const regMail = new RegExp('^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?)$')
      if (value != '' && value != undefined && !regMail.test(value)) {
        callback(new Error(this.globalDataArray.siteTemplateLanguage.mail_format_error))
      } else {
        callback()
      }
    },

    beforeUpload(file) {
      if (this.fileList.length > 0) {
        message.error(this.globalDataArray.siteTemplateLanguage.only_upload_one)

        return false
      }

      if (!/\.(?:zip|rar|7z)$/.test(file.name)) {
        message.error(this.globalDataArray.siteTemplateLanguage.file_format_error)

        return false
      }

      if (file.size > 1024 * 1024 * 10) {
        message.error(this.globalDataArray.siteTemplateLanguage.file_size_error)

        return false
      }

      this.fileList.push(file)

      return true
    },

    async customUpload(e) {
      const formData = new FormData()

      formData.append('file', e.file)

      this.uploading = true

      const { success, result, message } = await uploadFile(formData)

      this.uploading = false

      if (success) {
        e.onSuccess(result, e)
      }
      else {
        e.onError(message)
      }
    },

    removeFiles() {
      this.fileList = []
    },

    normFile(e) {
      return (e && e.fileList?.[0]?.response) ?? ''
    },

    onChangeTeamType(e) {
      this.removeFiles()
      this.teamType = e.target.value
    }
  }
}
</script>
