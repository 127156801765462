<template>
  <div class="formmsg" v-if="formmsg">
    <!-- 选择报名表单弹窗 -->
    <div class="pagePopMask pFull" @click.self="closePop"></div>
    <div class="pagePop pCenter">
      <div class="w pad60">
        <div class="tit fnt_24 relative">{{ globalDataArray.siteTemplateLanguage.apply_join }} <div class="xuzhiBtn" @click="targetXuzhi">{{ globalDataArray.siteTemplateLanguage.join_notice }}</div></div>
        <div class="tips">{{ globalDataArray.siteTemplateLanguage.please_write_info }} </div>
        <div class="cont">
          <div class="stform">
            <a-form :form="form" :model="data">
              <!-- 国内成人 -->
              <template v-if="curInfoArray.currentLanguge=='zh_CN'&&curInfoArray.currentLanguge!=''&&curInfoArray.templateId=='01'">
                <div class="item">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.name_text">
                    <a-input
                      v-decorator="['data.userName', {rules:[{required:true,message:globalDataArray.siteTemplateLanguage.enter_name_text}]}]"
                    />
                  </a-form-item>
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_phone">
                    <a-input
                      v-decorator="['data.userPhone',{rules:[{required:true,message:globalDataArray.siteTemplateLanguage.enter_member_tel}]}]"
                    />
                  </a-form-item>
                </div>
                <div class="item">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_email">
                    <a-input
                      v-decorator="['data.userMail',{ rules:[{ validator: validateNumber, trigger: 'blur'},{required:true,message:globalDataArray.siteTemplateLanguage.person_please_form_user_email}]}]"
                    />
                  </a-form-item>
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_company_type">
                    <a-radio-group @change="onChange" v-decorator="['data.companyType', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_company_type }] }]">
                      <a-radio :style="radioStyle" v-for="(item,index) in companyList" :value="item.itemValue" :key="index">{{ item.itemText }}</a-radio>
                    </a-radio-group>
                  </a-form-item>
                </div>

                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_company_name">
                  <a-input
                    v-decorator="['data.company',{ rules:[{required:true,message:globalDataArray.siteTemplateLanguage.person_please_form_company_name}]}]"
                  />
                </a-form-item>
                <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_job_type">
                  <a-radio-group @change="onChange" v-decorator="['data.jobType']">
                    <a-radio :style="radioStyle" v-for="(item,index) in jobList" :value="item.itemValue" :key="index">{{ item.itemText }}</a-radio>
                  </a-radio-group>
                </a-form-item>

                <div class="item">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.person_form_province">
                    <a-select @change="handleProvinceChange" :label-in-value="true" v-decorator="['data.countryCode', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_province }] }]">
                      <a-select-option v-for="item in provinceList" :key="item.parentId">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_city">
                    <a-select :label-in-value="true" v-decorator="['data.cityCode', { rules: [{ required: false, message: globalDataArray.siteTemplateLanguage.person_please_form_city }] }]">
                      <a-select-option v-for="item in cityList" :key="item.id">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </template>

              <!-- 国内青少年 -->
              <template v-if="curInfoArray.currentLanguge=='zh_CN'&&curInfoArray.currentLanguge!=''&&curInfoArray.templateId=='02'">
                <div class="item">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.name_text">
                    <a-input
                      v-decorator="['data.userName', {rules:[{required:true,message:globalDataArray.siteTemplateLanguage.enter_name_text}]}]"
                    />
                  </a-form-item>
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_school">
                    <a-input
                      v-decorator="['data.company',{ rules:[{required:true,message:globalDataArray.siteTemplateLanguage.person_please_form_school_name}]}]"
                    />
                  </a-form-item>
                </div>

                <div class="item">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.person_form_province">
                    <a-select @change="handleProvinceChange" :label-in-value="true" v-decorator="['data.countryCode', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_province }] }]">
                      <a-select-option v-for="item in provinceList" :key="item.parentId">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_city">
                    <a-select :label-in-value="true" v-decorator="['data.cityCode', { rules: [{ required: false, message: globalDataArray.siteTemplateLanguage.person_please_form_city }] }]">
                      <a-select-option v-for="item in cityList" :key="item.id">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </template>

              <!-- 国外成年 -->
              <template v-if="curInfoArray.currentLanguge=='en_US'&&curInfoArray.currentLanguge!=''&&curInfoArray.templateId=='01'">
                <div class="item">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.name_text">
                    <a-input
                      v-decorator="['data.userName', {rules:[{required:true,message:globalDataArray.siteTemplateLanguage.enter_name_text}]}]"
                    />
                  </a-form-item>
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_email">
                    <a-input
                      v-decorator="['data.userMail',{ rules:[{ validator: validateNumber, trigger: 'blur'},{required:true,message:globalDataArray.siteTemplateLanguage.person_please_form_user_email}]}]"
                    />
                  </a-form-item>
                </div>

                <div class="item">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.person_form_country">
                    <a-select @change="handleProvinceChange" :label-in-value="true" v-decorator="['data.countryCode', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_country }] }]">
                      <a-select-option v-for="item in provinceList" :key="item.parentId">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_city">
                    <a-select :label-in-value="true" v-decorator="['data.cityCode', { rules: [{ required: false }] }]">
                      <a-select-option v-for="item in cityList" :key="item.id">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </template>

              <!-- 国外青少年 -->
              <template v-if="curInfoArray.currentLanguge=='en_US'&&curInfoArray.currentLanguge!=''&&curInfoArray.templateId=='02'">
                <div class="item">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.name_text">
                    <a-input
                      v-decorator="['data.userName', {rules:[{required:true,message:globalDataArray.siteTemplateLanguage.enter_name_text}]}]"
                    />
                  </a-form-item>
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_member_school">
                    <a-input
                      v-decorator="['data.company',{ rules:[{required:true,message:globalDataArray.siteTemplateLanguage.person_please_form_school_name}]}]"
                    />
                  </a-form-item>
                </div>

                <div class="item">
                  <a-form-item :label="globalDataArray.siteTemplateLanguage.person_form_country">
                    <a-select @change="handleProvinceChange" :label-in-value="true" v-decorator="['data.countryCode', { rules: [{ required: true, message: globalDataArray.siteTemplateLanguage.person_please_form_country }] }]">
                      <a-select-option v-for="item in provinceList" :key="item.parentId">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item :label="globalDataArray.siteTemplateLanguage.team_user_city">
                    <a-select :label-in-value="true" v-decorator="['data.cityCode', { rules: [{ required: false }] }]">
                      <a-select-option v-for="item in cityList" :key="item.id">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </template>
            </a-form>
          </div>
        </div>

        <div class="btnBox fnt_18">
          <div class="btn btn1" @click="btnClick('1')">{{ globalDataArray.siteTemplateLanguage.right_away_sign }}</div>
          <div class="btn btn2" @click="btnClick('2')">{{ globalDataArray.siteTemplateLanguage.cancel }}</div>
        </div>

        <div class="close" @click.self="closePop"></div>
      </div>
    </div>

    <div class="pagePopMask pFull" v-if="showXuzhi" @click="targetXuzhi"></div>
    <div class="pagePop pCenter" v-if="showXuzhi">
      <div class="w pad60">
        <div class="tit fnt_32">{{ xuzhiCont.title }}</div>

        <div class="cont" v-html="xuzhiCont.content">
        </div>
        <div class="btnBox fnt_18">
          <div class="btn btn1" @click="targetXuzhi">{{ globalDataArray.siteTemplateLanguage.verify }}</div>
        </div>
        <div class="close" @click="targetXuzhi"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { getCompetitionDict, getProvinceList, getCityList, getArticle, getArticleDetail } from '@/api/api'
import Cookies from 'js-cookie'
import { joinTeam } from '@/api/saiti'
export default {
  name: 'Formmsg',
  props: {
    formmsg: {
      type: Boolean,
      default: false
    },
    curInfoArray: {
      type: Object,
      default: () => ({})
    },
    teamArray: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: [{
        teamName: '',
        teamType: '',
        leaderName: '',
        userPhone: '',
        userMail: '',
        companyType: '',
        company: '',
        jobType: '',
        countryCode: '',
        countryName: '',
        cityCode: '',
        cityName: '',
        cityList: [],
        provinceList: {}, // 公共的省或国家
        companyList: {}, // 公共的单位类型
        jobList: {}, // 公共的职业类型
        teamList: {} // 公共的团队类型
      }],

      form: this.$form.createForm(this, { name: 'dynamic_rule' }),

      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },

      provinceList: [{
        parentId: '1',
        title: '浙江'
      }, {
        parentId: '2',
        title: '江苏'
      }],

      cityList: [],

      defaultArea: [],
      xuzhiCont: '',
      showXuzhi: false,

      subblur: true // 避免重复提交
    }
  },
  watch: {
    formmsg: {
      immediate: true,
      handler(val) {
        if (val) {
          this.subblur = true
          this.init()
        }
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // console.log(this.curInfoArray,'formmsg')
      const that = this
      const params = 'dictCode=competition_signup_team_type,competition_signup_job_type,competition_signup_company_type'
      getCompetitionDict(params).then(res => {
        if (res.success) {
          // console.log(res)
          res.result.forEach((item, index) => {
            if (item.dictCode == 'competition_signup_company_type') { // 单位类型
              that.companyList = item.list // 公共的单位类型
            }
            if (item.dictCode == 'competition_signup_job_type') { // 职业类型
              that.jobList = item.list // 公共的职业类型
            }
            if (item.dictCode == 'competition_signup_team_type') { // 团队类型
              // that.teamList=item.list        //公共的团队类型

              const teamList = item.list
              // 两个说明
              getArticle('type=5,6').then(res => {
                if (res.success) {
                  const tipsMsg = res.result

                  teamList.forEach((item, index) => {
                    if (item.itemValue == '01') { // 个人
                      tipsMsg.forEach((e, i) => {
                        if (e.type == '5') {
                          item['tipsmsg'] = e.content
                        }
                      })
                    }

                    if (item.itemValue == '02') { // 公司
                      tipsMsg.forEach((e, i) => {
                        if (e.type == '6') {
                          item['tipsmsg'] = e.content
                        }
                      })
                    }
                  })

                  that.teamList = teamList
                }
              })
            }
          })
        }
      })

      getProvinceList().then(res => {
        if (res.success) {
          that.provinceList = res.result // 省或国家列表
        }
      })
    },
    onChange(e) {
      // console.log(e.target.value)
    },

    // 省市联动
    handleProvinceChange(e) {
      // console.log(e)
      const that = this
      const params = 'pid=' + e.key
      getCityList(params).then(res => {
        if (res.success) {
          that.cityList = res.result
          setTimeout(() => {
            // 选择后清空二级
            this.form.setFieldsValue({
              'data.cityCode': ''
            })
          })
        }
      })
    },

    btnClick(res) {
      // console.log(this.curInfoArray,this.teamArray,'formmsg')
      if (res == 1 && this.subblur) {
        this.form.validateFields((err, val) => {
          if (!err) {
            if (val.data.countryCode != '' && val.data.countryCode != undefined) {
              const countryKL = val.data.countryCode
              if (countryKL.key) {
                val.data['countryCode'] = countryKL.key
              }
              if (countryKL.label) {
                val.data['countryName'] = countryKL.label
              }
            }

            if (val.data.cityCode != '' && val.data.cityCode != undefined) {
              const cityKL = val.data.cityCode
              if (cityKL.key) {
                val.data['cityCode'] = cityKL.key
              }
              if (cityKL.label) {
                val.data['cityName'] = cityKL.label
              }
            } else {
              val.data['cityCode'] = ''
              val.data['cityName'] = ''
            }

            // 排列数据
            const params = {
              'categoryId': this.curInfoArray.categoryId,
              'parentId': this.teamArray.parentId,
              'competitionId': this.curInfoArray.competitionId,
              'teamName': this.teamArray.teamName,
              'teamType': this.teamArray.teamType,
              'teamNo': this.teamArray.teamNo,
              'nation': this.teamArray.nation,
              'templateId': this.curInfoArray.templateId,
              'userName': val.data.userName,
              'userPhone': val.data.userPhone,
              'userMail': val.data.userMail,
              'countryCode': val.data.countryCode,
              'countryName': val.data.countryName,
              'cityCode': val.data.cityCode,
              'cityName': val.data.cityName,
              'company': val.data.company,
              'companyType': val.data.companyType,
              'jobType': val.data.jobType,
              'isCaptain': 0,
              'leaderName': this.teamArray.leaderName,
              'groupId': this.teamArray.groupId,
              'grade': this.teamArray.grade
            }

            // 如果青少年未填,获取账号的手机和邮箱
            if (val.data.userPhone == '' || val.data.userPhone == null || val.data.userPhone == undefined) {
              params['userPhone'] = JSON.parse(Cookies.get('saiti_access_info')).phone
            }
            if (val.data.userMail == '' || val.data.userMail == null || val.data.userMail == undefined) {
              params['userMail'] = JSON.parse(Cookies.get('saiti_access_info')).email
            }

            // console.log('formmsg',params)
            // console.log(JSON.parse(Cookies.get("saiti_access_info")))

            joinTeam(params).then(res => {
              if (res.success) {
                this.subblur = true
                message.success(res.result)
                // 自定义事件，把res传给父组件,发射事件
                this.$emit('itemclick', 1) // itemclick  为事件名
                this.$emit('update:formmsg', false)
              } else {
                if (res.code == '403') {
                  message.error(res.message)
                }
              }
            })
          }
        })
      } else {
        this.$emit('itemclick', 2)
        this.$emit('update:formmsg', false)
      }
    },

    closePop() {
      this.$emit('itemclick', 2)
      this.$emit('update:formmsg', false)
    },
    targetXuzhi() {
      this.showXuzhi = !this.showXuzhi
      if (this.showXuzhi && this.xuzhiCont == '') {
        const params = 'id=1705132838718054401'
        getArticleDetail(params).then(res => {
          if (res.success) {
            this.xuzhiCont = res.result
          }
        })
      }
    },
    validateNumber(rule, value, callback) {
      const regMail = new RegExp('^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?)$')
      if (value != '' && value != undefined && !regMail.test(value)) {
        callback(new Error(this.globalDataArray.siteTemplateLanguage.mail_format_error))
      } else {
        callback()
      }
    }

  }
}
</script>
