/* 登录接口*/
import { axios } from '@/utils/http'

// 登录
export const login = (params) => axios({
  method: 'POST',
  contentType: 'json',
  data: {
    ...params
  },
  noToast: true
}, '/login/atomLogin')

// 获取与语言数据
export const siteLanguage = (params) => axios({
  method: 'GET',
  contentType: 'json',
  data: {
    ...params
  },
  noToast: true
}, '/init')

// 赛题字典列表
export const sitiDictData = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, 'competition/dict/list?' + params)

export const loginout = (params) => axios({
  method: 'POST',
  contentType: 'json',
  data: {
    ...params
  },
  noToast: true
}, '/login/logout')
