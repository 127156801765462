import api from '@/api/sapi.js'
function handleError(error, redirect) {
  console.error(error || '请求失败')
}
export default {
  async getSaitiInfo({ commit, state }, params) {
    try {
      const data = await api.getSaitiInfo(params, state.currentLanguge)
      commit('getSaitiInfo', data)
    } catch (error) {
      handleError(error)
    }
  },

  async getNewsInfo({ commit, state }, params) {
    try {
      const data = await api.getNewsInfo(params, state.currentLanguge)
      commit('getNewsInfo', data)
    } catch (error) {
      handleError(error)
    }
  }
}
