<template>
  <footer class="foots">
    <div class="w1440">
      <div class="top">
        <div class="logoAddress">
          <div class="logo"><img :src="globalDataArray.siteInfo.logoFooter" alt=""></div>
          <div v-if="curLang == 'zh_CN'" class="address fnt_16 mt_30"><a :href="'http://api.map.baidu.com/geocoder?address='+globalDataArray.siteInfo.address+'&output=html&src=webapp.baidu.openAPIdemo'" target="_blank">{{ globalDataArray.siteInfo.address }}</a></div>
          <div v-else class="address fnt_16 mt_30">{{ globalDataArray.siteInfo.address }}</div>
          <div class="mt-68px">
            <ul class="socially-pic m-0 flex list-none p-0">
              <li
                class="use-qrcode w-40px! h-40px! chat i-icons:chat relative hover:i-icons:chat-hover"
              >
                <img
                  class="lazyload absolute hidden"
                  :src="require('@/assets/images/chat.jpg')"
                  alt="chat"
                >
              </li>
              <li
                class="use-qrcode w-40px! h-40px! ml-16px wechat i-icons:wechat relative hover:i-icons:wechat-hover"
              >
                <img
                  class="lazyload absolute hidden"
                  :src="require('@/assets/images/wechat.jpg')"
                  alt="wechat"
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="contact">
          <div class="f1 " :class="curLang == 'zh_CN' ? 'fnt_30' : 'fnt_20'">{{ globalDataArray.siteTemplateLanguage.bot_msg_thanks }}</div>

          <div class="f2 fnt_16 mt_30">
            {{ globalDataArray.siteTemplateLanguage.site_contact }} : <a :href="'tel:'+globalDataArray.siteInfo.contact">{{ globalDataArray.siteInfo.contact }}</a><br>
            {{ globalDataArray.siteTemplateLanguage.site_email }} : <a :href="'mailto:'+globalDataArray.siteInfo.email">{{ globalDataArray.siteInfo.email }}</a>
          </div>
        </div>

        <div class="otherMail fnt_16">
          {{ globalDataArray.siteTemplateLanguage.bot_btn_zijin }} : <a :href="'mailto:'+globalDataArray.siteInfo.fundText">{{ globalDataArray.siteInfo.fundText }}</a><br>
          {{ globalDataArray.siteTemplateLanguage.bot_btn_project }} : <a :href="'mailto:'+globalDataArray.siteInfo.projectText">{{ globalDataArray.siteInfo.projectText }}</a><br>
          {{ globalDataArray.siteTemplateLanguage.bot_btn_law }} : <a :href="'mailto:'+globalDataArray.siteInfo.lawText">{{ globalDataArray.siteInfo.lawText }}</a><br>
          {{ globalDataArray.siteTemplateLanguage.bot_btn_media }} : <a :href="'mailto:'+globalDataArray.siteInfo.mediumText">{{ globalDataArray.siteInfo.mediumText }}</a><br>
          {{ globalDataArray.siteTemplateLanguage.bot_btn_jiandu }} : <span>{{ globalDataArray.siteTemplateLanguage.bot_btn_jiandu_text }}</span><br>
          {{ globalDataArray.siteTemplateLanguage.bot_btn_mail }} : <a :href="'mailto:'+globalDataArray.siteTemplateLanguage.bot_btn_mail_text">{{ globalDataArray.siteTemplateLanguage.bot_btn_mail_text }}</a>
        </div>
      </div>

      <div class="bot cf" :class="curLang == 'zh_CN' ? '' : 'foreignDiv'">
        <div class="otherLink fr" v-if="article.length>0">
          <template v-for="(item,index) in article">
            <div class="item" :key="index">
              <router-link v-if="item.linkUrl!=''&&item.linkUrl!=null" :to="item.linkUrl">{{ item.title }}</router-link>
              <router-link v-else-if="fromQuery!=''&&fromQuery!=null" target="_blank" :to="'/article/'+item.parentId+'?from='+fromQuery">{{ item.title }}</router-link>
              <router-link v-else target="_blank" :to="'/article/'+item.parentId">{{ item.title }}</router-link>
            </div><template v-if="index < article.length - 1"> / </template>
          </template>
        </div>

        <div class="copyright ovh" v-html="globalDataArray.siteInfo.copyright">
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Cookies from 'js-cookie'
import { getArticle } from '@/api/api'
export default {
  name: 'Foots',
  data() {
    return {
      article: [],
      curLang: '',
      fromQuery: ''
    }
  },
  mounted() {
    this.init()
    const that = this
    const currentLanguge = Cookies.get('DEFAULT_LANG')
    if (currentLanguge != null && currentLanguge != undefined) {
      that.curLang = currentLanguge
    } else {
      that.curLang = that.globalDataArray.siteLanguage[0].value
    }

    if (this.$route.query.from) {
      this.fromQuery = this.$route.query.from
    }
  },
  methods: {
    init() {
      const that = this
      const params = 'type=1,2,3,4'
      getArticle(params).then(res => {
        if (res.success) {
          // console.log(res)
          that.article = res.result
        }
      })
    }
  }
}
</script>

<style lang="scss">
.socially-pic {

  .use-qrcode {
    &:hover {
      img {
        display: block;
      }
    }

    img {
      bottom: 50px;
      left: 50%;
      width: 130px;
      height: 130px;
      padding: 10px;
      object-fit: cover;
      background-color: #fff;
      border-radius: 4px;
      transform: translateX(-50%);
      max-width: unset;
      max-height: unset;
    }
  }
}
</style>
