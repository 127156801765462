import heightline from './heightline'
const install = function(Vue) {
  Vue.directive('heightline', heightline)
}
if (typeof window !== 'undefined' && window.Vue) {
  window.heightline = heightline
  Vue.use(install)
}
heightline.install = install
export default heightline
