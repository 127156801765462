<template>
  <header class="heads" :class="menuopen ? 'open' : ''">
    <div class="w1440 cf relative">
      <div class="logo" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''"><router-link to="/"><img :src="globalDataArray.siteInfo.logoHead" alt=""></router-link></div>

      <div class="mainMenuMask"></div>
      <div class="mobileMenu" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
        <div class="mainMenu fnt_20">
          <div class="itemBox" v-for="(item,index) in globalDataArray.siteNavigation" :key="'item' + index">
            <router-link class="item" :to="item.linkUrl" exact-active-class="cur">
              {{ item.title }}
            </router-link>
          </div>
        </div>

        <div class="searchLanLogin">
          <div class="cf">
            <div class="fl">
              <div class="searchArea">
                <div class="btn"><router-link to="/search" /></div>
              </div>
            </div>
            <div class="fl">
              <div class="switchLan fl">
                <a-select v-model="curLang" @change="switchLangFun">
                  <a-select-option v-for="item in globalDataArray.siteLanguage" :key="item.value">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </div>
            </div>

            <div v-if="islogin" class="myMsgBtn fl" @click="toMymsgFun"> <span v-if="msgnum && msgnum>0">{{ msgnum }}</span> </div>

            <div class="fl logoutBtn">
              <a-dropdown v-if="islogin">
                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                  {{ curUserName }} <a-icon type="down" />
                </a>
                <a-menu slot="overlay" @click="loginoutFun">
                  <a-menu-item key="1">{{ curUserName }}</a-menu-item>
                  <a-menu-item key="2">{{ globalDataArray.siteTemplateLanguage.login_out }}</a-menu-item>
                </a-menu>
              </a-dropdown>
              <!-- <div  class="loginBtn fnt_16"><router-link to="/myrecord">{{globalDataArray.siteTemplateLanguage.btn_person_center}}</router-link></div> -->

              <div v-else class="loginBtn fnt_16" @click="handleLogin">{{ globalDataArray.siteTemplateLanguage.btn_login_register }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobileMenuBtn" @click="mianMenuFun"></div>
    </div>

    <!-- 老用户确认服务协议 -->
    <olduserxieyi :olduserxieyi.sync="olduserxieyi" @itemclick="olduserxieyiCallback" />
  </header>
</template>

<script>
import Cookies from 'js-cookie'
import { message } from 'ant-design-vue'
import { login, loginout } from '@/api/login/login'
export default {
  name: 'Heads',
  data() {
    return {
      isSelect: '/home',
      menuopen: false,
      islogin: false,
      curLang: '',
      curUserName: '',
      olduserxieyi: false // 老用户协议
    }
  },
  watch: {
    $route(to, from) {
      if (this.menuopen) {
        // 监听路由变化关闭弹层
        this.menuopen = false
      }
    }
  },
  computed: {
    msgnum() {
      return this.$store.state.msgnum
    }
  },
  created() {
    const that = this
    const atomUser = Cookies.get('atom_user')// 获取atom的cookie值
    const saiti_access_info = Cookies.get('saiti_access_info')
    // if (atomUser!=null && atomUser!=undefined){
    // Cookies.remove('atom_user',{domain:'.atomgit.com',path: '/'});
    // if (saiti_access_info==null || saiti_access_info==undefined || saiti_access_info =='') {
    that.checkLogin()
    // }
    // }

    // let curToken=Cookies.get('saiti_access_info')
    // if (curToken!=null && curToken!=undefined) {
    //   let saiti_access_info=JSON.parse(curToken)
    //   that.islogin=true
    //   if(saiti_access_info.nickname!=''&&saiti_access_info.nickname!=null&&saiti_access_info.nickname!=undefined){
    //     that.curUserName=saiti_access_info.nickname
    //   }else{
    //     that.curUserName=saiti_access_info.username
    //   }
    // }
  },

  mounted() {
    const that = this
    that.isSelect = that.$route.path

    // let token= Cookies.get("ATOMGIT_ACCESS_TOKEN");
    // console.log(token,"gt")
    // if (token!=null && token!=undefined){
    //   that.islogin=true
    // }

    const currentLanguge = Cookies.get('DEFAULT_LANG')
    if (currentLanguge != null && currentLanguge != undefined && currentLanguge != '') {
      that.curLang = currentLanguge
    } else {
      that.curLang = that.globalDataArray.siteLanguage[0].value
    }
  },
  methods: {

    mianMenuFun() {
      const that = this
      that.menuopen = !that.menuopen
    },

    handleLogin() {
      const redirectUrl = encodeURIComponent(location.href)
      Cookies.set('atom_redirect', redirectUrl, { domain: '.atomgit.com', expires: 180, path: '/', secure: true })
      location.href = this.logAuthUrl
    },

    checkLogin() {
      const that = this
      login({}).then(res => {
        // console.log(res)
        if (res.success) {
          // console.log(res)
          const result = JSON.stringify(res.result)
          Cookies.set('saiti_access_info', result, { domain: process.env.NODE_ENV === 'production' ? '.atomgit.com' : undefined, expires: 180, path: '/', secure: process.env.NODE_ENV === 'production' })
          that.islogin = true

          if (res.result.nickname != '' && res.result.nickname != null && res.result.nickname != undefined) {
            that.curUserName = res.result.nickname
          } else {
            that.curUserName = res.result.username
          }
          // that.msgnum=res.result.noReadMsg
          this.$store.commit('setSiteMsgnum', res.result.noReadMsg)
          this.olduserxieyiFun()
          // location.reload()
          // message.success(that.globalDataArray.siteTemplateLanguage.member_login_success_tips)
        } else {
          const saiti_access_info = Cookies.get('saiti_access_info')
          if (saiti_access_info != null && saiti_access_info != undefined && saiti_access_info != '') {
            Cookies.remove('saiti_access_info', { domain: process.env.NODE_ENV === 'production' ? '.atomgit.com' : undefined, path: '/' })
            that.islogin = false
          }
        }
      })
    },
    switchLangFun(e) {
      const that = this
      // console.log(e)
      const curlang = e
      const sitelang = that.globalDataArray.siteInfo.identification
      Cookies.set('DEFAULT_LANG', curlang, { domain: process.env.NODE_ENV === 'production' ? '.atomgit.com' : undefined, expires: 180, path: '/', secure: process.env.NODE_ENV === 'production' })
      Cookies.set('attr_lang', sitelang, { domain: process.env.NODE_ENV === 'production' ? '.atomgit.com' : undefined, expires: 180, path: '/', secure: process.env.NODE_ENV === 'production' })

      setTimeout(() => {
        if (typeof window !== 'undefined') {
          window.location.reload()
        }
      }, 100)
    },

    loginoutFun(e) {
      const that = this
      // console.log(e)
      if (e.key == '2') {
        // 退出登录
        const curmeta = that.$router.currentRoute.meta
        loginout({}).then(res => {
          if (res.success) {
            Cookies.remove('saiti_access_info', { domain: process.env.NODE_ENV === 'production' ? '.atomgit.com' : undefined, path: '/' })
            that.islogin = false

            const logoutUrl = that.logoutApiUrl + '?redirect_uri=' + encodeURIComponent(location.href)
            location.href = logoutUrl
          } else {
            message.error(res.message)
          }
        })
      } else {
        that.$router.push({ path: '/myrecord' })
      }
    },

    toMymsgFun() {
      this.$router.push({ path: '/mymessage' })
    },
    // 是否开启老用户协议展示
    olduserxieyiFun() {
      let token = ''
      let info = {}
      const saitiAccessInfo = Cookies.get('saiti_access_info')
      if (saitiAccessInfo != '' && saitiAccessInfo != undefined && saitiAccessInfo != null) {
        token = JSON.parse(saitiAccessInfo).token
        info = JSON.parse(saitiAccessInfo)

        if (token != '' && token != undefined && info.isAgree == 0 && info.isAgree != null) {
          this.olduserxieyi = true
        }
      }
      // console.log(token)
    },
    olduserxieyiCallback(res) {
      // console.log(res)
    }

  }
}
</script>
