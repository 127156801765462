import 'uno.css'
import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'

Vue.config.productionTip = false

// import { sync } from 'vuex-router-sync'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)
// Vue.mixin({
// 	metaInfo:{
// 		titleTemplate:"%s - 开放原子开源大赛"
// 	}
// })

import Cookies from 'js-cookie'

import Antd from 'ant-design-vue'
Vue.use(Antd)

import heightline from './directive/index'
Vue.use(heightline)

if (typeof window !== 'undefined') {
  const VueAwesomeSwiper = require('vue-awesome-swiper/ssr')
  Vue.use(VueAwesomeSwiper)

  const { WOW } = require('wowjs')
  window.WOW = WOW
}

// 引入全局注册组件
import './components'

// import { message } from 'ant-design-vue'

Vue.prototype.logAuthUrl = process.env.VUE_APP_LOGIN_URL
Vue.prototype.logoutApiUrl = process.env.VUE_APP_LOGOUT_URL

// 创建createApp工厂函数
export default function createApp() {
  const router = createRouter()
  const store = createStore()

  // sync(store,router)

  // 路由登录拦截
  router.beforeEach((to, from, next) => {
    const toPathUrlArr = to.path.split('http')
    let toPathUrl = to.path
    const reg = /^((ht|f)tps?):\/\/([\w-]+(\.[\w-]+)*\/?)+(\?([\w\-\.,@?^=%&:\/~\+#]*)+)?$/

    if (toPathUrlArr.length > 1) {
      let newtourl = 'http' + toPathUrlArr[1]
      if (from.query.from != '' && from.query.from != undefined) {
        newtourl += '?from=' + from.query.from

        if (to.query.id != '' && to.query.id != undefined) {
          newtourl += '&id=' + to.query.id
        }
      } else if (to.query.id != '' && to.query.id != undefined) {
        newtourl += '?id=' + to.query.id
      }
      if (typeof window !== 'undefined') {
        window.open(newtourl)
      }
      return
    } else if (reg.test(toPathUrl)) { // 如果是外链跳外链
      if (from.query.from != '' && from.query.from != undefined) {
        toPathUrl += '?from=' + from.query.from
        if (to.query.id != '' && to.query.id != undefined) {
          toPathUrl += '&id=' + to.query.id
        }
      } else if (to.query.id != '' && to.query.id != undefined) {
        toPathUrl += '?id=' + to.query.id
      }
      if (typeof window !== 'undefined') {
        window.open(toPathUrl)
      }
      return
    } else if (from.query.from != '' && from.query.from != undefined && !to.query.from) { // 如果是内链
      const queryFrom = { from: from.query.from }
      const totalParams = Object.assign(to.query, queryFrom)
      next({
        path: to.fullPath,
        query: totalParams
      })
    } else {
      // next()
      if (to.matched.some((res) => res.meta.requireAuth)) {
        // 验证是否需要登陆
        let id = ''
        const saiti_access_info = Cookies.get('saiti_access_info')
        if (saiti_access_info != '' && saiti_access_info != undefined && saiti_access_info != null) {
          id = JSON.parse(saiti_access_info).token
        }
        // console.log(id)
        if (id != '' && id != undefined) {
          // 查询本地存储信息是否已经登陆
          next()
        } else {
          next({
            path: '/',
            query: to.query
          })

          return
        }
      } else {
        next()
      }
    }
  })

  if (process.env.NODE_ENV === 'development' && process.env.VUE_ENV === 'client') {
    Cookies.set('ATOMGIT_ACCESS_TOKEN', process.env.VUE_APP_MOCK_ATOMGIT_ACCESS_TOKEN, { path: '/', expires: 365, sameSite: 'Lax' })
    Cookies.set('ATOMGIT_EXPIRES_IN', process.env.VUE_APP_MOCK_ATOMGIT_EXPIRES_IN, { path: '/', expires: 365, sameSite: 'Lax' })
  }

  // 创建 vue 实例
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  return { app, router, store }
}
