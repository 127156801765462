import Vue from 'vue'
import Router from 'vue-router'
import Cookies from 'js-cookie'

// 处理重定向的问题
const routerPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

Vue.use(Router)

// 每次用户请求都需要创建一个新的router实例
// 创建createRouter工厂函数
export default function createRouter() {
  // 创建router实例
  const router = new Router({
    mode: 'history',
    fallback: false,
    routes: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/index.vue')
      },
      {
        path: '/competition',
        name: 'saiti',
        component: () => import('../views/saiti/index.vue')
      },
      {
        path: '/competitionInfo',
        name: 'saitiinfo',
        component: () => import('../views/saiti/info.vue')
      },
      {
        path: '/zone',
        name: 'zone',
        component: () => import('../views/zone/index.vue')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../views/news/index.vue')
      },
      {
        path: '/newsinfo',
        name: 'newsinfo',
        component: () => import('../views/news/info.vue')
      },
      {
        path: '/article/:id',
        name: 'singlepage',
        component: () => import('../views/article/index.vue')
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('../views/search/index.vue')
      },
      {
        path: '/myrecord',
        name: 'myrecord',
        component: () => import('../views/person/record.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/myexamine',
        name: 'myexamine',
        component: () => import('../views/person/examine.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/myapply',
        name: 'myapply',
        component: () => import('../views/person/apply.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/mymessage',
        name: 'mymessage',
        component: () => import('../views/person/message.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/previewinfo',
        name: 'previewinfo',
        component: () => import('../views/saiti/previewinfo.vue')
      },
      {
        path: '*',
        name: 'nofound',
        component: () => import('../views/404/index.vue')
      }
    ]
  })

  router.afterEach((to) => {
    if (typeof window !== 'undefined' && window._oafs) {
      const useInfo = JSON.parse(Cookies.get('atom_user') || '{}') || {}
      window._oafs.setDefault({
        pageId: document.title,
        userId: useInfo ? useInfo.id : undefined,
        extra: {
          path: to.path
        }
      })
      window._oafs.pv()
    }
  })

  return router
}
