import { axios } from '../utils/http'

// 首页接口
export const getIndexInfo = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/index')

// 获取头部广告banner
export const getAdList = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/ad/list?' + params)

// 获取赛题数据字典
export const getCompetitionDict = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/competition/dict/list?' + params)

// 获取省份
export const getProvinceList = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/area/provinceList')

// 获取城市
export const getCityList = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/area/cityList?' + params)

// 文章接口
export const getArticle = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/article/list?' + params)

// 获取单条文章
export const getArticleDetail = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded'
}, '/article/detail?' + params)
