import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import actions from './actions'
import mutations from './mutations'

// import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)
export default function createStore() {
  return new Vuex.Store({
    // plugins: [createPersistedState()],    //store 持久化插件保证 刷新时store还在(ssr没有window不兼容)
    namespaced: true,
    state,
    actions,
    mutations
  })
}
