<template>
  <div id="app">
    <heads />
    <router-view />
    <foots :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''" />
  </div>
</template>
<script>
// import { siteLanguage } from '@/api/login/login'
export default {
  name: 'App',
  data() {
    return {

    }
  },
  computed: {

  },
  created() {
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style>
@import './assets/css/editor.content.css';
@import './assets/css/antd.css';
@import './assets/css/animate.min.css';
@import './assets/css/swiper.min.css';
@import './assets/css/common.css';
@import './assets/css/main.css';
</style>
