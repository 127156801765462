<template>
  <div class="formxieyi" v-if="formxieyi">
    <!-- 选择报名表单弹窗 -->
    <div class="pagePopMask pFull" @click.self="closePop"></div>
    <div class="pagePop pCenter">
      <div class="w">
        <div class="tit fnt_32 relative">{{ content.title }}</div>

        <div class="cont txtCont scrollable" ref="scrollDiv" v-html="content.content" @scroll="handleScroll">
        </div>

        <div class="btnBox fnt_18 btnBox2">
          <a-checkbox :disabled="true" v-if="disabled||!readed" />
          <a-checkbox :checked="checked" @change="onChange" v-else />
          <button class="btn btn1" :title="globalDataArray.siteTemplateLanguage.btn_has_read" :class="{disabled : counter > 0}" :disabled="counter > 0" @click="agreeFun('1')">{{ globalDataArray.siteTemplateLanguage.btn_has_read }}<span v-if="counter > 0">({{ counter }}s)</span></button>
          <div class="btn btn2" :title="globalDataArray.siteTemplateLanguage.cancel" @click="agreeFun('2')">{{ globalDataArray.siteTemplateLanguage.cancel }}</div>
        </div>
        <div class="close" @click.self="closePop"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { getIndexInfo } from '@/api/api'
export default {
  name: 'Formxieyi',
  props: {
    formxieyi: {
      type: Boolean,
      default: false
    },
    curInfoArray: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      counter: 0,
      interval: null,

      checked: false,
      disabled: true,
      content: {},
      readed: false,
      theEnd: true
    }
  },
  watch: {
    formxieyi: {
      immediate: true,
      handler(val) {
        if (val) {
          const that = this
          this.checked = false
          this.readed = false
          this.theEnd = true
          this.disabled = true
          this.counterFun()

          // 获取协议信息
          getIndexInfo({}).then(res => {
            if (res.success) {
              if (that.curInfoArray.templateId == '01') {
                that.content = res.result.adult
              } else {
                that.content = res.result.teenager
              }

              if (typeof document === 'object') {
                const scrollDiv = this.$refs.scrollDiv
                if (scrollDiv.scrollHeight <= scrollDiv.clientHeight) {
                  this.readed = true
                }
              }
            }
          })
        } else {
          if (this.interval != null) {
            this.counter = 0
            clearInterval(this.interval)
          }
        }
      }
    }
  },
  computed: {

  },
  methods: {
    onChange(e) {
      this.checked = e.target.checked
    },

    agreeFun(res) {
      // 自定义事件，把res传给父组件,发射事件
      if (res == 2) {
        this.$emit('itemclick', res) // itemclick  为事件名
        this.$emit('update:formxieyi', false)
      } else {
        if (this.checked) {
          this.$emit('itemclick', res) // itemclick  为事件名
          this.$emit('update:formxieyi', false)
        } else {
          message.error(this.globalDataArray.siteTemplateLanguage.msg_no_read)
        }
      }
    },

    closePop() {
      this.$emit('update:formxieyi', false)
      if (this.interval != null) {
        this.counter = 0
        clearInterval(this.interval)
      }
    },

    counterFun() {
      const that = this
      if (!that.checked) {
        that.counter = 5
        that.interval = setInterval(() => {
          that.counter--
          if (that.counter <= 0) {
            this.disabled = !this.disabled
            clearInterval(that.interval)
          }
        }, 1000)
      }
    },

    handleScroll() {
      if (typeof document === 'object') {
        const container = document.querySelector('.scrollable')
        const { scrollTop, scrollHeight, clientHeight } = container
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          this.readed = true
          if (this.theEnd) {
            this.theEnd = false
            message.success(this.globalDataArray.siteTemplateLanguage.to_the_end)
          }
        }
      }
    }

  }
}
</script>
